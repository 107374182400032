import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card, { CardBody, CardHeader, CardTitle } from '../../../components/bootstrap/Card';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { end } from '@popperjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { ProviderList } from '../../../store/slices/tpaList';
import ReactPaginate from 'react-paginate';
import useDarkMode from '../../../hooks/useDarkMode';
import { getColorNameWithIndex } from '../../../common/data/enumColors';
import { getFirstLetter } from '../../../helpers/helpers';

const ProviderLists = () => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const [showPage, setShoWPage] = useState(0);
	const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
	const [isLoding, setIsLoading] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [startdateError, setStartDateError] = useState(false);
	const [enddateError, setEndDateError] = useState(false);
	const [dateError2, setDateError2] = useState(false);
	const ProvidersList = useSelector((state) => state.tpaListSlice?.provList);
	const [itemOffset, setItemOffset] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);
	// Simulate fetching items from another resources.
	// (This could be items from props; or items loaded in a local state
	// from an API endpoint with useEffect and useState)
	const [endOffset, setEndOffset] = useState(itemOffset + itemsPerPage);
	const [currentItems, setCurrentItems] = useState(items.slice(itemOffset, endOffset));
	const [pageCount, setPageCount] = useState(
		Math.ceil(ProvidersList?.totalRecords / itemsPerPage),
	);

	let dates = new Date(startDate);
	let days = dates.getDate();
	let months = dates.getMonth();
	days = dates.getDate() < 10 ? '0' + dates.getDate() : dates.getDate();
	months = dates.getMonth() < 9 ? '0' + (dates.getMonth() + 1) : dates.getMonth() + 1;
	let newdate = dates.getFullYear() + '-' + months + '-' + days;

	let dates2 = new Date(endDate);
	let days2 = dates2.getDate();
	let months2 = dates2.getMonth();
	days2 = dates2.getDate() < 9 ? '0' + (dates2.getDate() + 1) : dates2.getDate() + 1;
	months2 = dates2.getMonth() < 9 ? '0' + (dates2.getMonth() + 1) : dates2.getMonth() + 1;
	let newdate2 = dates2.getFullYear() + '-' + months2 + '-' + days2;

	useEffect(() => {
		if(!localStorage.getItem("token")){
			window.location.href = "/"
		}
		let payload = {
			page_number: 0,
			records_per_page: itemsPerPage,
		};
		setIsLoading(true);
		dispatch(ProviderList({ payload, setIsLoading, key: 'small' }));
	}, []);

	useEffect(() => {
		setEndOffset(itemOffset + itemsPerPage);
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(ProvidersList?.totalRecords / itemsPerPage));
	}, [itemsPerPage, ProvidersList]);

	const handlePageClick = (event) => {
		setCurrentPage(event.selected);
		setShoWPage(event.selected);
		const newOffset = (event.selected * itemsPerPage) % ProvidersList?.totalRecords;
		setIsLoading(true);
		if (startDate === null || endDate === null) {
			let payload = {
				page_number: event.selected,
				records_per_page: itemsPerPage,
			};
			dispatch(ProviderList({ payload, setIsLoading, key: 'small' }));
		} else {
			let payload = {
				page_number: event.selected,
				records_per_page: itemsPerPage,
				startDate: startDate ? newdate : '',
				endDate: endDate ? newdate2 : '',
			};
			dispatch(ProviderList({ payload, setIsLoading, key: 'bid' }));
		}
		setItemOffset(newOffset);
	};

	const pageChange = (e) => {
		setItemsPerPage(e.target.value);
		setIsLoading(true);
		if (startDate === null || endDate === null) {
			let payload = {
				// page_number: currentPage == pageCount ? pageCount - 1 : currentPage,
				page_number:
					Math.ceil(ProvidersList?.totalRecords / e.target.value) > currentPage
						? currentPage
						: 0,

				records_per_page: e.target.value,
			};
			if (Math.ceil(ProvidersList?.totalRecords / e.target.value) < currentPage) {
				setCurrentPage(0);
			}
			dispatch(ProviderList({ payload, setIsLoading, key: 'small' }));
		} else {
			let payload = {
				// page_number: currentPage == pageCount ? pageCount - 1 : currentPage,
				startDate: startDate ? newdate : '',
				endDate: endDate ? newdate2 : '',
				page_number:
					Math.ceil(ProvidersList?.totalRecords / e.target.value) > currentPage
						? currentPage
						: 0,

				records_per_page: e.target.value,
			};
			if (Math.ceil(ProvidersList?.totalRecords / e.target.value) < currentPage) {
				setCurrentPage(0);
			}
			dispatch(ProviderList({ payload, setIsLoading, key: 'bid' }));
		}
	};

	const handleSearch = (e) => {
		if (!startDate || startDate?.length === 0) {
			setStartDateError(true);
		} else if (!endDate || endDate?.length === 0) {
			setEndDateError(true);
		} else if (endDate < startDate) {
			setDateError2(true);
		} else {
			let payload = {
				page_number: 0,
				records_per_page: itemsPerPage,
				startDate: startDate ? newdate : '',
				endDate: endDate ? newdate2 : '',
			};
			// return;
			setIsLoading(true);
			dispatch(ProviderList({ payload, setIsLoading, key: 'bid' }));
		}
	};

	const handleReset = () => {
		if (startDate !== null || endDate !== null) {
			setStartDate(null);
			setEndDate(null);
			let payload = {
				page_number: 0,
				records_per_page: itemsPerPage,
			};
			setIsLoading(true);
			dispatch(ProviderList({ payload, setIsLoading, key: 'small' }));
		}
	};

	return (
		localStorage.getItem("token") && <>
			{isLoding ? (
				<div className='spinner-border loader-dec' role='status'>
					<span className='visually-hidden'>Loading...</span>
				</div>
			) : (
				<div className='dashboard-page new-dashboard-provider'>
					<div className='dashboard-block new-top-dasboard prolist-dash'>
						{/* <h3>Provider List</h3> */}
						<div className='dashboard-block new-top-dasboard pro-list'>
							Provider List
						</div>
						<div className='filter-wrap'>
							<p>Filter by Registration Date:</p>
							<div className='date-wrap'>
								<div className='date-picker-box'>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											className='form-control'
											value={startDate}
											disableFuture={true}
											onChange={(e) => {
												setStartDate(e);
												setStartDateError(false);
												setEndDateError(false);
												setDateError2(false);
											}}
										/>
									</LocalizationProvider>
									{startdateError ? (
										<p style={{ color: 'red' }} className='error-select'>
											Please select start date
										</p>
									) : null}
								</div>

								<div className='date-picker-box'>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											className='form-control'
											value={endDate}
											disableFuture={true}
											onChange={(e) => {
												setEndDate(e);
												setStartDateError(false);
												setEndDateError(false);
												setDateError2(false);
											}}
										/>
									</LocalizationProvider>
									{enddateError ? (
										<p style={{ color: 'red' }} className='error-select'>
											Please select end date
										</p>
									) : null}
									{dateError2 ? (
										<p
											style={{ color: 'red' }}
											className='error-select big-error-select'>
											End date cannot be smaller than start date
										</p>
									) : null}
								</div>

								<button className='search-btn' onClick={handleSearch}>
									Search
								</button>

								<button className='search-btn' onClick={handleReset}>
									Reset
								</button>
							</div>
						</div>
					</div>
					<Card data-tour='list'>
						<CardBody className='table-responsive' isScrollable>
							<table className='table table-modern'>
								<thead>
									<tr>
										{/* <th className='headings serial-num' scope='col'>
											S.No
										</th> */}
										<th className='headings' scope='col'>
											Organization Name
										</th>
										<th className='headings' scope='col'>
											Organization Type
										</th>
										<th className='headings' scope='col'>
											Email
										</th>
										<th className='headings' scope='col'>
											Registration Date
										</th>
										<th className='headings' scope='col'>
											Action
										</th>
									</tr>
								</thead>
								<tbody className='tpa-tble-color no-data-wrap'>
									{ProvidersList?.response?.provider_details &&
									ProvidersList?.response?.provider_details?.length > 0 ? (
										ProvidersList?.response?.provider_details?.map(
											(item, index) => (
												<tr key={index}>
													{/* <td className='heading-rows serial-num'>
														{index + 1}.
													</td> */}
													<td className='heading-rows main-head-row '>
														<div className='flex-shrink-0'>
															<div
																className='ratio ratio-1x1 me-3'
																style={{ width: 48 }}>
																<div
																	className={`bg-l${
																		darkModeStatus
																			? 'o25'
																			: '25'
																	}-${getColorNameWithIndex(
																		index,
																	)} text-${getColorNameWithIndex(
																		index,
																	)} rounded-2 d-flex align-items-center justify-content-center`}>
																	<span className='fw-bold head-data'>
																		{getFirstLetter(
																			item?.company_name
																				? item?.company_name
																				: 'N A',
																		)}
																	</span>
																</div>
															</div>
														</div>

														{item?.company_name
															? item?.company_name
															: 'N/A'}
													</td>
													<td className='heading-rows'>
														{' '}
														{
															item?.organization_details
																?.organization_type
														}
													</td>
													<td className='heading-rows'>{item?.email}</td>
													<td className='heading-rows'>
														{item?.registration_date}
													</td>
													<td className='heading-rows'>
														<Link
															to='/provider-detail'
															className='view-item'
															state={{ prov_id: item?.provider_id }}>
															View
														</Link>
													</td>
												</tr>
											),
										)
									) : (
										<div className='no-data-found'>
											<h4>No data found</h4>
										</div>
									)}
								</tbody>
							</table>
							<div className='pagintion'>
								{ProvidersList?.totalRecords > 10 ? (
									<div className='entry-wrp'>
										<span className='entry-text'>Show entries</span>
										<div className='select-wrap'>
											<select
												value={itemsPerPage}
												onChange={(e) => pageChange(e)}>
												<option value={10}>10</option>
												<option value={15}>15</option>
												<option value={20}>20</option>
											</select>
											<i className='icon-arrow'></i>
										</div>
									</div>
								) : null}
								{ProvidersList?.response?.provider_details &&
								ProvidersList?.response?.provider_details?.length > 0 ? (
									<ReactPaginate
										breakLabel='...'
										nextLabel=''
										onPageChange={handlePageClick}
										pageRangeDisplayed={5}
										pageCount={pageCount}
										previousLabel=''
										forcePage={
											currentPage == pageCount ? pageCount - 1 : currentPage
										}
										renderOnZeroPageCount={null}
										className='pagination-list'
									/>
								) : null}
							</div>
						</CardBody>
					</Card>
				</div>
			)}
		</>
	);
};

export default ProviderLists;
