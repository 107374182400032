import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstanceAuth, axiosInstanceAuth1, axiosInstanceAuthLogout } from '../../api/axios';
import endPoints from '../../api/endpoints';
import Toasts from '../../components/bootstrap/Toasts';
import { encryptionObj } from '../../pages/Encrypt';

export const userLogin = createAsyncThunk('login', async ({ payload, addToast }, thunkApi) => {
	const redirect = payload.navigate;
	let finalData = await encryptionObj(payload);
	return axiosInstanceAuth1
		.post(endPoints.login, {data:finalData})
		.then((response) => {
			localStorage.setItem('authData', finalData);
			if (response?.data?.response?.is_qr_scanned === false) {
				localStorage.setItem('isEmail', payload.email);
				localStorage.setItem('is_qr_scanned', response?.data?.response?.is_qr_scanned);
				localStorage.setItem('qr_code', response?.data?.response?.qr_code);
				redirect('/mfa-authenticate');
				return thunkApi.fulfillWithValue({
					qr_token: response?.data?.response,
					is_qr_scanned: response?.data?.response?.is_qr_scanned,
					data: payload,
					qr_code: response?.data?.response?.qr_code,
				});
			} else if (response?.data?.response?.is_qr_scanned === true) {
				localStorage.setItem('isEmail', payload.email);
				redirect('/mfa-c-authenticate');
				return thunkApi.fulfillWithValue({
					qr_token: response?.data?.response,
					is_qr_scanned: response?.data?.response?.is_qr_scanned,
					data: payload,
					qr_code: response?.data?.response?.qr_code,
				});
			} else {
				addToast(
					<Toasts
						title='Toasts notifications'
						icon='VerifiedUser'
						iconColor='danger'
						time='Now'
						isDismiss>
						{response?.data?.response}
					</Toasts>,
					{
						autoDismiss: true,
						autoDismissTimeout: 2000,
					},
				);
				return;
			}
		})
		.catch((error) => {
			return thunkApi.rejectWithValue(error);
		});
});

export const qrScan = createAsyncThunk('scanQr', async ({ payload, addToast }, thunkApi) => {
	const redirect = payload.navigate;
	return axiosInstanceAuth
		.put(endPoints.isScanned, payload)
		.then((response) => {
			if (response?.data?.status === 200) {
				redirect('/mfa-c-authenticate');
				return thunkApi.fulfillWithValue(response?.data?.response);
			} else {
				addToast(
					<Toasts
						title='Toasts notifications'
						icon='VerifiedUser'
						iconColor='danger'
						time='Now'
						isDismiss>
						{'Something went wrong'}
					</Toasts>,
					{
						autoDismiss: true,
						autoDismissTimeout: 2000,
					},
				);
			}
		})
		.catch((error) => {
			return thunkApi.rejectWithValue(error);
		});
});

export const logoutUser = createAsyncThunk('logoutUser', async (_, thunkApi) => {
	return axiosInstanceAuthLogout
		.get(endPoints.logout+"?session="+localStorage.getItem("session"))
		.then((response) => {
			localStorage.removeItem('token');
			localStorage.removeItem('isEmail');
			localStorage.removeItem("session");
			window.location.href = '/';
			if (response?.data?.code === 200) {
				return thunkApi.fulfillWithValue(response?.data?.response);
			} 
		})
		.catch((error) => {
			localStorage.removeItem('token');
			localStorage.removeItem('isEmail');
			localStorage.removeItem("session");
			window.location.href = '/';
			return thunkApi.rejectWithValue(error);
		});
});

export const qrVerify = createAsyncThunk('verify', async ({ payload, addToast }, thunkApi) => {
	const redirect = payload.navigate;
	return axiosInstanceAuth1
		.post(endPoints.verifyQR, payload)
		.then((response) => {
			if (response?.data?.response?.access_token) {
				localStorage.removeItem("authData")
				localStorage.setItem('token', response?.data?.response?.access_token);
				localStorage.setItem('refreshToken', response?.data?.response?.refresh_token);
				localStorage.setItem('session', response?.data?.response?.session_state);
				redirect('/dashboard');
				return thunkApi.fulfillWithValue(response?.data?.response?.access_token);
			} else {
				addToast(
					<Toasts
						title='Toasts notifications'
						icon='VerifiedUser'
						iconColor='danger'
						time='Now'
						isDismiss>
						{response?.data?.response}
					</Toasts>,
					{
						autoDismiss: true,
						autoDismissTimeout: 2000,
					},
				);
			}
		})
		.catch((error) => {
			return thunkApi.rejectWithValue(error);
		});
});

/**
 export const userLogin = createAsyncThunk('login', async ({ payload, addToast }, thunkApi) => {
	const redirect = payload.navigate;
	return axiosInstanceAuth
		.post(endPoints.login, { email: payload.email, password: payload.password })
		.then((response) => {
			if (response?.data?.response?.access_token) {
				localStorage.setItem('token', response?.data?.response?.access_token);
				redirect('/mfa-authenticate');
				addToast(
					<Toasts
						title='Toasts notifications'
						icon='VerifiedUser'
						iconColor='primary'
						time='Now'
						isDismiss>
						Logged in successfully.
					</Toasts>,
					{
						autoDismiss: true,
						autoDismissTimeout: 2000,
					},
				);
				return thunkApi.fulfillWithValue({ token: response?.data?.response?.access_token });
			}
		})
		.catch((error) => {
			console.log('error', error);
			return thunkApi.rejectWithValue(error);
		});
});
 */

const verify =
	typeof window !== 'undefined' ? (localStorage.getItem('token') ? true : false) : false;

const initialState = {
	verify,
	QR: JSON.parse(localStorage.getItem('is_qr_scanned')),
	qr_code: localStorage.getItem('qr_code'),
	email: localStorage.getItem('isEmail'),
};

const loginSlice = createSlice({
	name: 'islogin',
	initialState,
	reducers: {
		logout: (state, action) => {
			state.islogin = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(userLogin.pending, (state, action) => {});
		builder.addCase(userLogin.fulfilled, (state, action) => {
			state.logged = action.payload;
			state.QR = action.payload.is_qr_scanned;
			state.qr_code = action.payload.qr_code;
			state.getEmail = action.payload.isEmail;
		});
		builder.addCase(userLogin.rejected, (state, action) => {});
		builder.addCase(qrScan.pending, (state, action) => {});
		builder.addCase(qrScan.fulfilled, (state, action) => {
			state.scanned = action.payload;
			delete state.QR;
			delete state.qr_code;
		});
		builder.addCase(qrScan.rejected, (state, action) => {});
		builder.addCase(qrVerify.pending, (state, action) => {});
		builder.addCase(qrVerify.fulfilled, (state, action) => {
			state.verify = action.payload;
		});
		builder.addCase(qrVerify.rejected, (state, action) => {});
	},
});

export const { logout } = loginSlice.actions;

export default loginSlice.reducer;
