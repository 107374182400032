import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody, CardHeader, CardTitle } from '../../../components/bootstrap/Card';

import data from '../../../common/data/dummyProductData';
import { demoPages } from '../../../menu';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../components/PaginationButtons';
import useSortableData from '../../../hooks/useSortableData';
import Icon from '../../../components/icon/Icon';
import useDarkMode from '../../../hooks/useDarkMode';
import useTourStep from '../../../hooks/useTourStep';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { tpaListDateReports } from '../../../store/slices/tpaList';
import { useToasts } from 'react-toast-notifications';
import ReactPaginate from 'react-paginate';
import { tpaListDateReports2 } from '../../../store/slices/tpaList';

const ReportList = () => {
	/**
	 * For Tour
	 */
	useTourStep(6);
	const [isLoding, setIsLoading] = useState(true);
	const param = useParams();
	const { addToast } = useToasts();
	const dispatch = useDispatch();
	const { themeStatus, darkModeStatus } = useDarkMode();
	const tpaReportDate = useSelector((state) => state.tpaListSlice.tpaDate);
	const tpaReportDate2 = useSelector((state) => state.tpaListSlice.tpaDate);
	const [date, setDate] = useState(new Date());
	const [currentPage, setCurrentPage] = useState(0);
	const [perPage, setPerPage] = useState(15);
	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + perPage;
	const pageCount = Math.ceil(tpaReportDate2?.totalRecords / perPage);

	const [filterMenu, setFilterMenu] = useState(false);
	const formik = useFormik({
		initialValues: {
			minPrice: '',
			maxPrice: '',
			categoryName: '3D Shapes',
			companyA: true,
			companyB: true,
			companyC: true,
			companyD: true,
		},
		onSubmit: (values) => {
			setFilterMenu(false);
		},
	});

	useEffect(() => {
		if(!localStorage.getItem("token")){
			window.location.href = "/"
		}
		const payload = {
			id: param.id,
			page_number: currentPage,
			records_per_page: perPage,
		};
		dispatch(tpaListDateReports({ payload, setIsLoading }));
		dispatch(tpaListDateReports2({ payload, setIsLoading }));
	}, [dispatch, param.id]);

	const handlePageClick = (event) => {
		const newOffset = (event.selected * perPage) % tpaReportDate2?.totalRecords;
		const payload = {
			id: param.id,
			page_number: event.selected,
			records_per_page: perPage,
		};
		dispatch(tpaListDateReports2({ payload, setIsLoading }));
	};

	const filteredData = data.filter(
		(f) =>
			// Category
			f.category === formik.values.categoryName &&
			// Price
			(formik.values.minPrice === '' || f.price > formik.values.minPrice) &&
			(formik.values.maxPrice === '' || f.price < formik.values.maxPrice) &&
			//	Company
			((formik.values.companyA ? f.store === 'Company A' : false) ||
				(formik.values.companyB ? f.store === 'Company B' : false) ||
				(formik.values.companyC ? f.store === 'Company C' : false) ||
				(formik.values.companyD ? f.store === 'Company D' : false)),
	);

	const { items, requestSort, getClassNamesFor } = useSortableData(filteredData);
	const onCurrentPageItems = dataPagination(items, currentPage, perPage);

	return (
		localStorage.getItem("token") && <PageWrapper title={demoPages.listPages.subMenu.listBoxed.text}>
			{/* {tpaReport && tpaReport !== 'No Data Found' ? ( */}
			{isLoding ? (
				<div class='spinner-border loader-dec' role='status'>
					<span class='visually-hidden'>Loading...</span>
				</div>
			) : (
				tpaReportDate && (
					<Page>
						<Card stretch data-tour='list'>
							<CardHeader>
								<CardTitle>TPA Report </CardTitle>
							</CardHeader>
							<CardHeader>
								<CardTitle>
									Company: {''}
									{tpaReportDate?.response?.tpa_company_name}
								</CardTitle>
								<div className='top-space'>
									<div className='gaping'>
										<div className='number-box green list-box'></div>
										Pass
									</div>
									<div className='gaping'>
										<div className='number-box red list-box'></div>
										Fail
									</div>
									<div className='gaping'>
										<div className='number-box yellow list-box'></div>
										In Progress
									</div>
								</div>
							</CardHeader>
							<CardBody className='table-responsive' isScrollable>
								<table className='table table-modern report-table'>
									<thead>
										<tr>
											<th scope='col'>Date</th>
											{tpaReportDate?.response?.file_setting_details
												?.claimDetails ? (
												<th scope='col'>Claim Details</th>
											) : null}
											{tpaReportDate?.response?.file_setting_details
												?.claimHeaders ? (
												<th scope='col'>Claim Headers</th>
											) : null}
											{tpaReportDate?.response?.file_setting_details
												?.eligibility ? (
												<th scope='col'>Eligibility Data</th>
											) : null}
											{tpaReportDate?.response?.file_setting_details
												?.accumulator ? (
												<th scope='col'>Accumulator</th>
											) : null}
											{tpaReportDate?.response?.file_setting_details
												?.groupPlan ? (
												<th scope='col'>Group&Plan</th>
											) : null}
											<th scope='col'>Action</th>
											{/* <td /> */}
										</tr>
									</thead>
									<tbody>
										{typeof tpaReportDate !== 'string' &&
											tpaReportDate?.response?.date_wise_details?.map(
												(item, index) => (
													<tr key={index}>
														<td>{item.date}</td>

														{item?.details?.map((items, index) => {
															return (
																<td key={index}>
																	<span className='number-box'>
																		{items.total_count}
																	</span>
																	<span className='number-box green'>
																		{items.success_count}
																	</span>
																	<span className='number-box red'>
																		{items.failure_count}
																	</span>
																	{items.in_progress_count > 0 ? (
																		<span className='number-box yellow'>
																			<span
																				style={{
																					color: 'black',
																				}}>
																				{
																					items.in_progress_count
																				}
																			</span>
																		</span>
																	) : null}
																</td>
															);
														})}
														<td>
															<Link
																className='view-show'
																to={`/list-pages/boxed-list/${param.id}?date=${item.date}`}>
																View
															</Link>
														</td>
													</tr>
												),
											)}
									</tbody>
								</table>
							</CardBody>
							{/* {tpaReportDate && tpaReportDate?.date_wise_details?.length > 16 ? ( */}
							<div id='react-paginate'>
								<ReactPaginate
									breakLabel='...'
									nextLabel='Next >'
									onPageChange={handlePageClick}
									pageRangeDisplayed={5}
									pageCount={pageCount}
									previousLabel='< Previous'
									renderOnZeroPageCount={null}
								/>
							</div>
							{/* // ) : null} */}
						</Card>
					</Page>
				)
			)}
		</PageWrapper>
	);
};

export default ReportList;
