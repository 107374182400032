import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { userLogin } from '../../../store/slices/login';
import { userSignUp } from '../../../store/slices/signUp';
import { useToasts } from 'react-toast-notifications';
import { encryption } from '../../Encrypt';
import { resetLink } from '../../../store/slices/tpaList';

// eslint-disable-next-line react/prop-types

const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			{/* <div className='text-center h1 fw-bold mt-5'>Welcome</div> */}
			<div className='text-center h4 text-muted mb-5'>
				Enter your email, so we can send you a link to reset your password!
			</div>
		</>
	);
};

const ForgotPass = ({ isSignUp }) => {
	const [isLoding, setIsLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [loginEmailError, setLoginEmailError] = useState(false);
	const { addToast } = useToasts();
	const { darkModeStatus } = useDarkMode();
	const [isNewUser, setIsNewUser] = useState(isSignUp);
	const dispatch = useDispatch();

	const handleReset = async (e) => {
		e.preventDefault();
		const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
		if (email.length === 0 || !regex.test(email)) {
			setLoginEmailError(true);
		} else {
			let finalData = await encryption(email);
			let payload = encodeURIComponent(finalData);
			// setIsLoading(true);
			dispatch(resetLink({ payload, setIsLoading, addToast }));
		}
	};

	const keyDown = (e) => {
		if (e.charCode === 13) {
			handleReset(e);
		}
	};

	return (
		<PageWrapper
			// title={isNewUser ? 'Sign Up' : 'Login'}
			// title={'Login'}
			className='backgroun-new'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<div
										className={classNames(
											'heading-font text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										{/* <Logo width={200} /> */}
										TRES HEALTH
									</div>
									<p>YOUR PARTNER TO BETTER HEALTH</p>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-lo10-dark': darkModeStatus,
									})}></div>

								<LoginHeader isNewUser={isNewUser} />

								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup id='login-password' isFloating label='Email'>
											<Input
												// className={'mb-3'}
												type='text'
												value={email}
												onChange={(e) => {
													setEmail(e.target.value);
													setLoginEmailError(false);
												}}
												onKeyPress={keyDown}
											/>
										</FormGroup>
										{loginEmailError ? (
											<div className='error-wrap'>
												<div className='error lg-msz'>
													Please enter the valid email.
												</div>
											</div>
										) : null}
									</div>
									<div className='col-12'>
										<Button
											color='warning'
											className='w-100 py-3 login-class'
											onClick={handleReset}>
											Send Link
										</Button>
									</div>
									{/* )} */}
								</form>
							</CardBody>
						</Card>
						{/* <div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Terms of use
							</a>
						</div> */}
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
ForgotPass.propTypes = {
	isSignUp: PropTypes.bool,
};
ForgotPass.defaultProps = {
	isSignUp: false,
};

export default ForgotPass;
