import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Card, {
	CardBody,
	CardHeader,
	CardTitle,
	CardLabel,
} from '../../../components/bootstrap/Card';
import {
	providerCount,
	activeProviderCount,
	activeProviderList,
	adminSerach,
	ProviderList,
} from '../../../store/slices/tpaList';
import useDarkMode from '../../../hooks/useDarkMode';
import { getColorNameWithIndex } from '../../../common/data/enumColors';
import { getFirstLetter } from '../../../helpers/helpers';

const ProviderDash = () => {
	const { darkModeStatus } = useDarkMode();
	const [isLoding, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const ProvidCount = useSelector((state) => state.tpaListSlice?.activeProv);
	const ActiveProvidCount = useSelector((state) => state.tpaListSlice?.activeCount);
	const ActiveProviderList = useSelector((state) => state.tpaListSlice?.activeList);
	const adminSearchCount = useSelector((state) => state.tpaListSlice?.searchAd);
	const ProvidersList = useSelector((state) => state.tpaListSlice?.provList);

	useEffect(() => {
		dispatch(providerCount());
		dispatch(activeProviderCount());
		dispatch(adminSerach());
	}, []);

	useEffect(() => {
		let payload = {
			page_number: 0,
			records_per_page: 10,
		};
		setIsLoading(true);
		dispatch(activeProviderList({ payload, setIsLoading }));
	}, []);

	useEffect(() => {
		if(!localStorage.getItem("token")){
			window.location.href = "/"
		}
		let payload = {
			page_number: 0,
			records_per_page: 10,
		};
		setIsLoading(true);
		dispatch(ProviderList({ payload, setIsLoading, key: 'small' }));
	}, []);

	return (
		localStorage.getItem("token") && <>
			{isLoding ? (
				<div class='spinner-border loader-dec' role='status'>
					<span class='visually-hidden'>Loading...</span>
				</div>
			) : (
				<div className='dashboard-page new-dashboard-provider'>
					<div className='dashboard-block new-top-dasboard'>Provider Dashboard</div>
					<div className='card-wrap card-wrapper-new'>
						<Card className='number-card first-box'>
							<CardBody className='card-body-wrp new-data-card-body'>
								<div className='first main-text'>
									<CardLabel icon='SupervisorAccount'>
										<h4>Registered Providers</h4>
									</CardLabel>
								</div>
								<div className='second-block-wrp'>
									<div className='second main-text'>
										<h3>
											{
												ProvidCount?.response?.registered_provider_count
													?.today
											}
										</h3>
										<h4>Today</h4>
									</div>
									<div className='third main-text'>
										<h3>
											{ProvidCount?.response?.registered_provider_count?.week}
										</h3>
										<h4>07 Days</h4>
									</div>
									<div className='forth main-text'>
										<h3>
											{
												ProvidCount?.response?.registered_provider_count
													?.month
											}
										</h3>
										<h4>30 Days</h4>
									</div>
									<div className='fifth main-text'>
										<h3>
											{
												ProvidCount?.response?.registered_provider_count
													?.total
											}
										</h3>
										<h4>Total</h4>
									</div>
								</div>
							</CardBody>
						</Card>
						<Card className='number-card second-box active-user'>
							<CardBody className='card-body-wrp new-data-card-body'>
								<div className='first main-text'>
									<CardLabel icon='CheckCircle'>
										<h4>Active Providers</h4>
									</CardLabel>
								</div>
								<div className='second-block-wrp'>
									<div className='second main-text'>
										<h3>
											{
												ActiveProvidCount?.response?.active_provider_count
													?.today
											}
										</h3>
										<h4>Today</h4>
									</div>
									<div className='third main-text'>
										<h3>
											{
												ActiveProvidCount?.response?.active_provider_count
													?.week
											}
										</h3>
										<h4>7 Days</h4>
									</div>
									<div className='forth main-text'>
										<h3>
											{
												ActiveProvidCount?.response?.active_provider_count
													?.month
											}
										</h3>
										<h4>30 Days</h4>
									</div>
								</div>
							</CardBody>
						</Card>
						<Card className='number-card third-box  two-row-block three-new'>
							<CardBody className='card-body-wrp new-data-card-body'>
								<div className='first main-text'>
									<CardLabel icon='Search'>
										<h4>Eligibility Searches</h4>
									</CardLabel>
								</div>
								<div className='second-block-wrp'>
									<div className='second main-text'>
										<h3>{adminSearchCount?.today_eligibility_search_count}</h3>
										<h4>Today</h4>
									</div>
									<div className='third main-text'>
										<h3>{adminSearchCount?.total_eligibility_search_count}</h3>
										<h4>Total</h4>
									</div>
								</div>
							</CardBody>
						</Card>
						<Card className='number-card third-box two-row-block four-new'>
							<CardBody className='card-body-wrp new-data-card-body'>
								<div className='first main-text'>
									<CardLabel icon='Search'>
										<h4>Claim Searches</h4>
									</CardLabel>
								</div>
								<div className='second-block-wrp'>
									<div className='second main-text'>
										<h3>{adminSearchCount?.today_claim_search_count}</h3>
										<h4>Today</h4>
									</div>
									<div className='third main-text'>
										<h3>{adminSearchCount?.total_claim_search_count}</h3>
										<h4>Total</h4>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<Card data-tour='list'>
						<CardHeader>
							<div className='block-wrap-table'>
								<CardLabel icon='Receipt'>
									<CardTitle>Latest Registered Providers</CardTitle>
								</CardLabel>
								<h5>
									<Link to='/provider-list' className='view-all view-list'>
										View all
									</Link>
								</h5>
							</div>
						</CardHeader>
						<CardBody className='table-responsive' isScrollable>
							<table className='table table-modern'>
								<thead>
									<tr>
										{/* <th className='headings serial-num' scope='col'>
											S.No
										</th> */}
										<th className='headings' scope='col'>
											Organization Name
										</th>
										<th className='headings' scope='col'>
											Organization Type
										</th>
										<th className='headings' scope='col'>
											Email
										</th>
										<th className='headings' scope='col'>
											Registration Date
										</th>
										<th className='headings' scope='col'>
											Action
										</th>
									</tr>
								</thead>
								<tbody className='tpa-tble-color'>
									{ProvidersList?.response?.provider_details?.map(
										(item, index) =>
											index < 3 && (
												<tr key={index}>
													{/* <td className='heading-rows serial-num'>
														{index + 1}
													</td> */}
													<td className='heading-rows main-head-row'>
														<div className='flex-shrink-0'>
															<div
																className='ratio ratio-1x1 me-3'
																style={{ width: 48 }}>
																<div
																	className={`bg-l${
																		darkModeStatus
																			? 'o25'
																			: '25'
																	}-${getColorNameWithIndex(
																		index,
																	)} text-${getColorNameWithIndex(
																		index,
																	)} rounded-2 d-flex align-items-center justify-content-center`}>
																	<span className='fw-bold head-data'>
																		{getFirstLetter(
																			item?.company_name,
																		)}
																	</span>
																</div>
															</div>
														</div>

														{item?.company_name
															? item?.company_name
															: 'N/A'}
													</td>
													<td className='heading-rows'>
														{' '}
														{
															item?.organization_details
																?.organization_type
														}
													</td>
													<td className='heading-rows'>{item?.email}</td>
													<td className='heading-rows'>
														{item?.registration_date}
													</td>
													<td className='heading-rows'>
														<Link
															to='/provider-detail'
															className='view-item view-list'
															state={{ prov_id: item?.provider_id }}>
															View
														</Link>
													</td>
												</tr>
											),
									)}
								</tbody>
							</table>
						</CardBody>
					</Card>
					<Card data-tour='list'>
						<CardHeader>
							<CardLabel icon='Receipt'>
								<CardTitle>Latest Active Providers</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody className='table-responsive' isScrollable>
							<table className='table table-modern'>
								<thead>
									<tr>
										{/* <th className='headings serial-num' scope='col'>
											S.No
										</th> */}
										<th className='headings' scope='col'>
											Organization Name
										</th>
										<th className='headings' scope='col'>
											Organization Type
										</th>
										<th className='headings' scope='col'>
											Email
										</th>
										<th className='headings' scope='col'>
											Registration Date
										</th>
										<th className='headings' scope='col'>
											Action
										</th>
									</tr>
								</thead>
								<tbody className='tpa-tble-color'>
									{ActiveProviderList?.response?.provider_details?.map(
										(item, index) =>
											index < 5 && (
												<tr key={index}>
													{/* <td className='heading-rows serial-num'>
														{index + 1}
													</td> */}
													<td className='heading-rows main-head-row'>
														<div className='flex-shrink-0'>
															<div
																className='ratio ratio-1x1 me-3'
																style={{ width: 48 }}>
																<div
																	className={`bg-l${
																		darkModeStatus
																			? 'o25'
																			: '25'
																	}-${getColorNameWithIndex(
																		index,
																	)} text-${getColorNameWithIndex(
																		index,
																	)} rounded-2 d-flex align-items-center justify-content-center`}>
																	<span className='fw-bold head-data'>
																		{getFirstLetter(
																			item?.company_name,
																		)}
																	</span>
																</div>
															</div>
														</div>

														{item?.company_name
															? item?.company_name
															: 'N/A'}
													</td>
													<td className='heading-rows'>
														{' '}
														{item?.organization_details
															?.organization_type
															? item?.organization_details
																	?.organization_type
															: 'N/A'}
													</td>
													<td className='heading-rows'>{item?.email}</td>
													<td className='heading-rows'>
														{item?.registration_date}
													</td>
													<td className='heading-rows'>
														<Link
															to='/provider-detail'
															className='view-item view-list'
															state={{ prov_id: item?.provider_id }}>
															View
														</Link>
													</td>
												</tr>
											),
									)}
								</tbody>
							</table>
						</CardBody>
					</Card>
				</div>
			)}
		</>
	);
};

export default ProviderDash;
