import { useDispatch } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { userLogin } from '../../../store/slices/login';
import { userSignUp } from '../../../store/slices/signUp';
import { useToasts } from 'react-toast-notifications';

// eslint-disable-next-line react/prop-types
const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

const Login = ({ isSignUp }) => {
	const [loginEmail, setLoginEmail] = useState('');
	const [loginPass, setLoginPass] = useState('');
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const [isNewUser, setIsNewUser] = useState(isSignUp);
	const [showLoginEmailError, setShowLoginEmailError] = useState(false);
	const [showLoginPassError, setShowLoginPassError] = useState(false);
	const [passValid, setPassValid] = useState(false);
	const [emailValid, setEmailValid] = useState(false);
	const { addToast } = useToasts();
	const navigate = useNavigate();
	const islogin = useSelector((state) => state.loginSlice.verify);

	const handleLogin = () => {
		const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;

		if (loginEmail.length === 0 || !regex.test(loginEmail)) {
			setShowLoginEmailError(true);
			return;
		} else if (loginPass.length === 0) {
			setShowLoginPassError(true);
			return;
		} else {
			const payload = {
				email: loginEmail,
				password: loginPass,
				navigate,
			};
			dispatch(userLogin({ payload, addToast }));
		}
	};

	useEffect(() => {
		if (islogin) {
			navigate('/dashboard');
		}
	}, [islogin]);

	const keyDown = (e) => {
		if (e.charCode === 13) {
			handleLogin();
		}
	};

	return (
		<PageWrapper
			// title={isNewUser ? 'Sign Up' : 'Login'}
			// title={'Login'}
			className='backgroun-new'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'heading-font text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										{/* <Logo width={200} /> */}
										TRES HEALTH
									</Link>
									<p>YOUR PARTNER TO BETTER HEALTH</p>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-lo10-dark': darkModeStatus,
									})}></div>

								<LoginHeader isNewUser={isNewUser} />

								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup
											className={'mb-3'}
											id='login-username'
											isFloating
											label='Email'>
											<Input
												value={loginEmail}
												type='email'
												onChange={(e) => {
													setLoginEmail(e.target.value);
													setShowLoginEmailError(false);
													setEmailValid(false);
												}}
											/>
										</FormGroup>
										{showLoginEmailError ? (
											<p style={{ color: 'red' }}>
												Please enter the valid email
											</p>
										) : null}
										{emailValid ? (
											<p style={{ color: 'red' }}>
												Please enter a valid email
											</p>
										) : null}

										<FormGroup id='login-password' isFloating label='Password'>
											<Input
												type='password'
												value={loginPass}
												onChange={(e) => {
													setLoginPass(e.target.value);
													setShowLoginPassError(false);
													setPassValid(false);
												}}
												onKeyPress={keyDown}
											/>
										</FormGroup>
										{showLoginPassError ? (
											<p style={{ color: 'red' }}>
												Please enter the password
											</p>
										) : null}
										{passValid ? (
											<p style={{ color: 'red' }}>
												Password must be 8 characters long
											</p>
										) : null}
									</div>
									<Link to={'/forgot-password'} className='forget-pass-link'>
										Forgot Password ?
									</Link>
									<div className='col-12'>
										<Button
											color='warning'
											className='w-100 py-3 login-class'
											onClick={handleLogin}>
											Login
										</Button>
									</div>
									{/* )} */}
								</form>
							</CardBody>
						</Card>
						{/* <div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Terms of use
							</a>
						</div> */}
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
