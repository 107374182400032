const endPoints = {
	login: '/sign-in',
	signUp: '/sign-up',
	tpaList: '/admin/all-tpa',
	tpaReportById: '/admin/report/file-details/tpa/',
	tpaReports: '/admin/report/files-process-report/tpa/',
	tpaReportsByDate: '/admin/report/date-wise-report/tpa/',
	tpaTodayReport: '/admin/dashboard/dashboard-report-today',
	addTPA: '/admin/add-tpa',
	viewTPA: '/admin/tpa/',
	editTPA: '/admin/tpa/',
	isScanned: '/verify/mark-qr-scanned',
	verifyQR: '/account/verify-mfa',
	rawData: '/admin/file/raw-data/file/',
	latestFile: '/admin/report/latest-file/tpa/',
	provider_count: '/admin/provider/count',
	activeProvCount: '/admin//provider/active-count',
	activeProvList: '/admin/active-provider',
	detailProv: '/admin/provider',
	searchesCount: '/admin/provider/count/id',
	adminSearch: '/admin/provider/count/data-search',
	providerList: '/admin/all-provider',
	changePassword: '/account/change-password',
	profile: '/profile',
	resetPass: '/password/reset-link',
	linkCheck: '/password/reset-link/check',
	pass_reset: '/account/forgot-password',
	lost_device: '/lost-device',
	refresh: "/token-refresh",
	logout: "/logout-account",
};

export default endPoints;
