import React, { useState, useEffect } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, {
	CardBody,
	CardFooter,
	CardHeader,
	CardTitle,
} from '../../../components/bootstrap/Card';
import useTourStep from '../../../hooks/useTourStep';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import QRCode from 'react-qr-code';
import Button from '../../../components/bootstrap/Button';
import { qrScan, qrVerify } from '../../../store/slices/login';
import { useToasts } from 'react-toast-notifications';
import { decryptionObj } from '../../Encrypt';

const CodeAuth = () => {
	/**
	 * For Tour
	 */
	useTourStep(6);
	const [isCode, setIsCode] = useState('');
	const [isCodeError, setIsCodeError] = useState(false);
	const { addToast } = useToasts();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const qrAuth = useSelector((state) => state.loginSlice.logged);
	const getQR = useSelector((state) => state.loginSlice.QR);
	const getCode = useSelector((state) => state.loginSlice.qr_code);
	const email = localStorage.getItem('isEmail');

	const handleSubmit = async () => {
		if (isCode === '') {
			setIsCodeError(true);
		} else {
			const authData = localStorage.getItem('authData');
			let finalData = await decryptionObj(authData);
			const payload = {
				email: email,
				code: isCode,
				identifier:JSON.parse(finalData)?.password,
				navigate,
			};
			dispatch(qrVerify({ payload, addToast }));
		}
	};

	const keyDown = (e) => {
		if (e.charCode === 13) {
			handleSubmit();
		}
	};

	return (
		<PageWrapper className='backgroun-new'>
			<Page>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card stretch className='card-qr' data-tour='list'>
							<CardTitle>Please enter the security code</CardTitle>
							<p>We are asking this code based on your two-factor authentication.</p>
							<FormGroup
								className={'mb-3'}
								id='login-username'
								isFloating
								label='Enter your code'>
								<Input
									value={isCode}
									type='text'
									onChange={(e) => {
										setIsCode(e.target.value);
										setIsCodeError(false);
									}}
									onKeyPress={keyDown}
								/>
							</FormGroup>
							{isCodeError ? (
								<p style={{ color: 'red' }}>Please enter the code</p>
							) : null}
							<div className='col-12 new-class'>
								<Button
									onClick={handleSubmit}
									color='warning'
									className='w-100 py-3 login-class'>
									Submit
								</Button>
							</div>
							<CardFooter>
								<Link to='/lost-device'>Lost your authentication device ?</Link>
							</CardFooter>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default CodeAuth;
