import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Card, { CardBody, CardLabel } from '../../../components/bootstrap/Card';
import { providerDetail, serachDetail } from '../../../store/slices/tpaList';
import Button from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';
import SubHeader, { SubheaderSeparator, SubHeaderLeft } from '../../../layout/SubHeader/SubHeader';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';

const ProviderDetail = () => {
	const [isLoding, setIsLoading] = useState(true);
	let { state } = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const ProviderDetail = useSelector((state) => state.tpaListSlice?.detailsProvider);
	const SearchDetail = useSelector((state) => state.tpaListSlice?.detailsSearch);

	const handleBack = () => {
		navigate(-1);
	};

	useEffect(() => {
		if(!localStorage.getItem("token")){
			window.location.href = "/"
		}
		setIsLoading(true);
		let payload = {
			id: state?.prov_id,
		};
		dispatch(serachDetail({ payload, setIsLoading }));
		dispatch(providerDetail({ payload, setIsLoading }));
	}, []);

	return (
		localStorage.getItem("token") && <>
			{isLoding ? (
				<div class='spinner-border loader-dec' role='status'>
					<span class='visually-hidden'>Loading...</span>
				</div>
			) : (
				<PageWrapper>
					<div className='dashboard-page new-dashboard-provider hca-healthcare'>
						<SubHeader>
							<SubHeaderLeft>
								<div className='dashboard-block  new-top-dasboard'>
									<div className='hca-block'>
										<Button
											color='primary'
											isLink
											icon='ArrowBack'
											tag='a'
											to={'/provider-list'}>
											Back to List
										</Button>
										{ProviderDetail?.last_active_date !== '' ||
										ProviderDetail?.last_active_time !== '' ? (
											<SubheaderSeparator />
										) : null}
										{ProviderDetail?.last_active_date !== '' ||
										ProviderDetail?.last_active_time !== '' ? (
											<div className='last-active'>
												Last Active:
												<div className='date'>
													{ProviderDetail?.last_active_date}
												</div>
												<div className='time'>
													{ProviderDetail?.last_active_time}
												</div>
											</div>
										) : null}
										{/* <button className='bck-btn' onClick={handleBack}>
								<i className='icon-bck' icon='Back'></i>
							</button> */}
									</div>
								</div>
							</SubHeaderLeft>
						</SubHeader>
						<div className='col-lg-8'>
							<Page>
								<div className='company-det'>
									<h3>{ProviderDetail?.company_name}</h3>
								</div>

								<div className='main-crd-new-wrp'>
									<div className='card-wrap card-wrapper-new'>
										<Card className='number-card third-box two-row-block three-new'>
											<CardBody className='card-body-wrp new-data-card-body '>
												<div className='first main-text'>
													<CardLabel icon='Search'>
														<h4>Eligibility Searches</h4>
													</CardLabel>
												</div>
												<div className='second-block-wrp'>
													<div className='second main-text'>
														<h3>
															{
																SearchDetail?.today_eligibility_search_count
															}
														</h3>
														<h4>Today</h4>
													</div>
													<div className='third main-text'>
														<h3>
															{
																SearchDetail?.total_eligibility_search_count
															}
														</h3>
														<h4>Total</h4>
													</div>
												</div>
											</CardBody>
										</Card>
										<Card className='number-card third-box two-row-block four-new'>
											<CardBody className='card-body-wrp new-data-card-body'>
												<div className='first main-text'>
													<CardLabel icon='Search'>
														<h4>Claim Searches</h4>
													</CardLabel>
												</div>
												<div className='second-block-wrp'>
													<div className='second main-text'>
														<h3>
															{SearchDetail?.today_claim_search_count}
														</h3>
														<h4>Today</h4>
													</div>
													<div className='third main-text'>
														<h3>
															{SearchDetail?.total_claim_search_count}
														</h3>
														<h4>Total</h4>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
								</div>
								<Card>
									<div className='details-wrapper'>
										<div className='personal-detail'>
											<h2>Personal Details</h2>
											<div className='block-wrp'>
												<h3>First Name:</h3>
												<h4>{ProviderDetail?.first_name}</h4>
											</div>
											<div className='block-wrp'>
												<h3>Last Name:</h3>
												<h4>{ProviderDetail?.last_name}</h4>
											</div>
											<div className='block-wrp'>
												<h3>Email Address:</h3>
												<h4>{ProviderDetail?.email}</h4>
											</div>
											<div className='block-wrp'>
												<h3>Phone Number:</h3>
												<h4>{ProviderDetail?.phone_number}</h4>
											</div>
											<div className='block-wrp'>
												<h3>Extension:</h3>
												<h4>
													{ProviderDetail?.extension
														? ProviderDetail?.extension
														: 'N/A'}
												</h4>
											</div>
										</div>
										<div className='organizational-detail'>
											<h2>Organizational Details</h2>
											<div className='block-wrp'>
												<h3>Organization Name:</h3>
												<h4>
													{ProviderDetail?.company_name
														? ProviderDetail?.company_name
														: 'N/A'}
												</h4>
											</div>
											<div className='block-wrp'>
												<h3>Organization Type:</h3>
												<h4>
													{
														ProviderDetail?.organization_details
															?.organization_type
													}
												</h4>
											</div>
										</div>
									</div>
								</Card>
							</Page>
						</div>
					</div>
				</PageWrapper>
			)}
		</>
	);
};

export default ProviderDetail;
