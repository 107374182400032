import React, { useState, useEffect } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, {
	CardBody,
	CardFooter,
	CardHeader,
	CardTitle,
} from '../../../components/bootstrap/Card';
import useTourStep from '../../../hooks/useTourStep';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import QRCode from 'react-qr-code';
import Button from '../../../components/bootstrap/Button';
import { qrScan, qrVerify } from '../../../store/slices/login';
import { useToasts } from 'react-toast-notifications';
import { decryptionObj } from '../../Encrypt';

const Authentication = () => {
	/**
	 * For Tour
	 */
	useTourStep(6);
	const { addToast } = useToasts();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const getCode = useSelector((state) => state.loginSlice.qr_code);
	const showEmail = localStorage.getItem('isEmail');
	const value = getCode;

	useEffect(() => {
		if (getCode === undefined || getCode === null) {
			navigate('/');
		}
	}, []);

	const onQrSubmit = async () => {
		const authData = localStorage.getItem('authData');
		let finalData = await decryptionObj(authData);
		const payload = {
			email: showEmail,
			qr_scanned: true,
			identifier:JSON.parse(finalData)?.password,
			navigate,
		};
		dispatch(qrScan({ payload, addToast }));
	};

	return (
		<PageWrapper className='backgroun-new'>
			<Page>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card stretch className='card-qr' data-tour='list'>
							<CardTitle>
								Please scan the QR code from your Microsoft/Google authenticator app
							</CardTitle>
							<br />
							<div
								style={{
									height: 'auto',
									maxWidth: 150,
								}}>
								<img src={value} />
							</div>
							<br />
							<div className='col-12 new-color'>
								<Button onClick={onQrSubmit} color='warning' className='w-100 py-3'>
									Submit
								</Button>
							</div>
							<CardFooter>
								<Link to='/lost-device'>Lost your authentication device ?</Link>
							</CardFooter>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Authentication;
