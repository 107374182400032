import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { userLogin } from '../../../store/slices/login';
import { userSignUp } from '../../../store/slices/signUp';
import { useToasts } from 'react-toast-notifications';
import { encryptionObj, decryption } from '../../Encrypt';
import { checkLink, passReset } from '../../../store/slices/tpaList';
import Icon from '../../../components/icon/Icon';

// eslint-disable-next-line react/prop-types

const LoginHeader = ({ isNewUser }) => {
	const linkRes = useSelector((state) => state.tpaListSlice?.chckLink);
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			{/* <div className='text-center h1 fw-bold mt-5'>Welcome</div> */}
			{linkRes?.code === 200 ? (
				<div className='text-center h4 text-muted mb-5'>Reset your password here!</div>
			) : (
				''
			)}
		</>
	);
};

const ResetPass = ({ isSignUp }) => {
	const [isLoding, setIsLoading] = useState(false);
	const [loginPass, setLoginPass] = useState('');
	const [confirmPass, setConfirmPass] = useState('');
	const [ConfrmPassValid, setCnfrmPassValid] = useState('');
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const [isNewUser, setIsNewUser] = useState(isSignUp);
	const [showConfrmError, setShowCnfrmError] = useState(false);
	const [showLoginPassError, setShowLoginPassError] = useState(false);
	const [passValid, setPassValid] = useState(false);
	const [emailValid, setEmailValid] = useState(false);
	const { addToast } = useToasts();
	const navigate = useNavigate();
	const search = new URLSearchParams(window.location.search);
	const islogin = useSelector((state) => state.loginSlice.verify);
	const linkRes = useSelector((state) => state.tpaListSlice?.chckLink);
	const [isPasswordShowing, setIsPasswordShowing] = useState(false);
	const [isPasswordShowing1, setIsPasswordShowing1] = useState(false);

	const togglePassword = () => {
		setIsPasswordShowing((prev) => !prev);
	};
	const togglePassword1 = () => {
		setIsPasswordShowing1((prev) => !prev);
	};

	useEffect(() => {
		(async () => {
			if (search.get('id') !== null) {
				let window = search.get('id').replace(/\s/g, '+');
				let idd = await decryption(window);
				let newId = encodeURIComponent(search.get('id'));
				let payload = {
					id: window,
					key: search.get('key'),
				};
				// return;
				setIsLoading(true);
				dispatch(checkLink({ payload, setIsLoading, addToast }));
			} else {
				window.location = '/';
			}
		})();
	}, []);

	const handleReset = async (e) => {
		e.preventDefault();
		const passRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
		if (loginPass.length === 0 || !passRegex.test(loginPass)) {
			setShowLoginPassError(true);
			return;
		} else if (confirmPass.length < 8) {
			setShowCnfrmError(true);
		} else if (confirmPass !== loginPass) {
			setCnfrmPassValid(true);
		} else {
			if (search.get('id') !== null) {
				let window = search.get('id').replace(/\s/g, '+');
				let idd = await decryption(window);
				let newId = encodeURIComponent(search.get('id'));
				let data = {
					id: idd,
					key: search.get('key'),
					new_password: loginPass,
					confirm_password: confirmPass,
				};
				let finalData = await encryptionObj(data);
				let payload = {
					data: finalData,
				};
				setIsLoading(true);
				dispatch(passReset({ payload, setIsLoading, addToast }));
			} else {
				window.location = '/';
			}
		}
	};

	const keyDown = (e) => {
		if (e.charCode === 13) {
			handleReset(e);
		}
	};

	return (
		<PageWrapper
			// title={isNewUser ? 'Sign Up' : 'Login'}
			// title={'Login'}
			className='backgroun-new'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<div
										className={classNames(
											'heading-font text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										{/* <Logo width={200} /> */}
										TRES HEALTH
									</div>
									<p>YOUR PARTNER TO BETTER HEALTH</p>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-lo10-dark': darkModeStatus,
									})}></div>

								<LoginHeader isNewUser={isNewUser} />
								<form className='row g-4'>
									{/* {linkRes?.code === 200 ? ( */}
									{/* <> */}
									<div className='col-12'>
										<div className='form-wrp-pass'>
											<FormGroup
												id='login-password'
												isFloating
												label='Password'>
												<Input
													className={'mb-3'}
													value={loginPass}
													type={isPasswordShowing ? 'text' : 'password'}
													onChange={(e) => {
														setLoginPass(e.target.value);
														setShowLoginPassError(false);
														setPassValid(false);
													}}
												/>
											</FormGroup>
											<div className='pass-icon-wrap'>
												{isPasswordShowing ? (
													<Icon icon='eye' onClick={togglePassword} />
												) : (
													<Icon
														icon='EyeSlash'
														onClick={togglePassword}
													/>
												)}
											</div>
										</div>
										{showLoginPassError ? (
											<div className='error-wrap'>
												<div className='error lg-msz'>
													1. Ensure that password contains atleast 8
													characters including both upper and lower case
													letters
												</div>
												<div className='error lg-msz'>
													2. Include symbols like @ , _ ,# ,* and numbers
												</div>
											</div>
										) : null}
										<div className='form-wrp-pass'>
											<FormGroup
												id='login-password'
												isFloating
												label='Confirm Password'>
												<Input
													type={isPasswordShowing1 ? 'text' : 'password'}
													value={confirmPass}
													onChange={(e) => {
														setConfirmPass(e.target.value);
														setShowCnfrmError(false);
														setCnfrmPassValid(false);
													}}
													onKeyPress={keyDown}
												/>
											</FormGroup>
											<div className='pass-icon-wrap'>
												{isPasswordShowing1 ? (
													<Icon icon='eye' onClick={togglePassword1} />
												) : (
													<Icon
														icon='EyeSlash'
														onClick={togglePassword1}
													/>
												)}
											</div>
										</div>
										{showConfrmError ? (
											<div className='error-wrap'>
												<div className='error lg-msz'>
													1. Ensure that password contains atleast 8
													characters including both upper and lower case
													letters
												</div>
												<div className='error lg-msz'>
													2. Include symbols like @ , _ ,# ,* and numbers
												</div>
											</div>
										) : null}
										{ConfrmPassValid ? (
											<p style={{ color: '#ea868f' }}>
												Confirm password must be same as password
											</p>
										) : null}
									</div>
									<div className='col-12'>
										<Button
											color='warning'
											className='w-100 py-3 login-class'
											onClick={handleReset}>
											Send request
										</Button>
									</div>
									{/* </> */}
									{/* ) : (
										<p className='link-check'>{linkRes?.response}</p>
									)} */}
								</form>
							</CardBody>
						</Card>
						{/* <div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Terms of use
							</a>
						</div> */}
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
ResetPass.propTypes = {
	isSignUp: PropTypes.bool,
};
ResetPass.defaultProps = {
	isSignUp: false,
};

export default ResetPass;
