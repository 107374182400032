import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card from '../../../components/bootstrap/Card';
import { demoPages } from '../../../menu';
import useTourStep from '../../../hooks/useTourStep';
import { rawDetails } from '../../../store/slices/tpaList';
import ReactPaginate from 'react-paginate';

const DetailedPage = () => {
	/**
	 * For Tour
	 */
	useTourStep(6);
	const [isLoding, setIsLoading] = useState(true);
	const param = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();
	const rawData = useSelector((state) => state.tpaListSlice.rawDataa);
	const [currentPage, setCurrentPage] = useState(0);
	const [perPage, setPerPage] = useState(15);
	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + perPage;
	const pageCount = Math.ceil(rawData?.totalRecords / perPage);
	const [newCount, setNewCount] = useState('');

	const [filterMenu, setFilterMenu] = useState(false);
	const formik = useFormik({
		initialValues: {
			minPrice: '',
			maxPrice: '',
			categoryName: '3D Shapes',
			companyA: true,
			companyB: true,
			companyC: true,
			companyD: true,
		},
		onSubmit: (values) => {
			setFilterMenu(false);
		},
	});

	useEffect(() => {
		if(!localStorage.getItem("token")){
			window.location.href = "/"
		}
		const payload = {
			id: param.id,
			date: searchParams.get('date'),
			pageNumber: 0,
			recordsPerPage: 15,
		};
		dispatch(rawDetails({ payload, setIsLoading }));
	}, [dispatch, param.id]);

	const handlePageClick = (event) => {
		setNewCount(event.selected);
		const newOffset = (event.selected * perPage) % rawData?.totalRecords;
		const payload = {
			id: param.id,
			date: searchParams.get('date'),
			pageNumber: event.selected,
			recordsPerPage: perPage,
		};
		dispatch(rawDetails({ payload, setIsLoading }));
		setItemOffset(newOffset);
	};

	return (
		localStorage.getItem("token") && <PageWrapper title={demoPages.listPages.subMenu.listBoxed.text}>
			<>
				{isLoding ? (
					<div class='spinner-border loader-dec' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				) : rawData?.response?.length > 0 ? (
					<Page>
						{rawData?.response ? (
							rawData?.response?.map((item, index) => (
								<Card
									stretch
									data-tour='list'
									className='card-non-round'
									key={index}>
									<ul style={{ maxHeight: '600px', overflow: 'auto' }}>
										<li style={{ marginBottom: '20px' }}>
											<span className='details-title'>
												Raw Data Id &nbsp; :
											</span>{' '}
											&nbsp; {item?.raw_data_id}
										</li>
										<br />
										{item?.exception !== null ? (
											<li style={{ marginBottom: '20px' }}>
												<span className='details-title'>
													Exception &nbsp; :
												</span>
												&nbsp; {item?.exception}
											</li>
										) : null}
									</ul>
								</Card>
							))
						) : (
							<h3>No Data Found</h3>
						)}
						{rawData?.totalRecords && rawData?.totalRecords > 15 ? (
							<div id='react-paginate'>
								<ReactPaginate
									breakLabel='...'
									nextLabel='Next >'
									onPageChange={handlePageClick}
									pageRangeDisplayed={1}
									pageCount={pageCount}
									marginPagesDisplayed={2}
									previousLabel='< Previous'
									renderOnZeroPageCount={null}
								/>
							</div>
						) : null}
					</Page>
				) : (
					<div className='no-data'>
						<h3>No Data Found</h3>
					</div>
				)}
			</>
		</PageWrapper>
	);
};

export default DetailedPage;
