import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Toast } from 'bootstrap';
import axiosInstance from '../../api/axios';
import endPoints from '../../api/endpoints';
import Toasts from '../../components/bootstrap/Toasts';

export const userSignUp = createAsyncThunk(
	'login',
	async ({ payload, thunkApi, setIsNewUser, addToast }) => {
		// const redirect = payload.navigate;
		return axiosInstance
			.post(endPoints.signUp, {
				firstname: payload.firstname,
				middlename: payload.middlename,
				lastname: payload.lastname,
				email: payload.email,
				password: payload.password,
			})
			.then((response) => {
				if (response?.data?.response?.status === 'User is Created') {
					localStorage.setItem('signUptoken', response?.data?.response?.access_token);
					setTimeout(() => setIsNewUser(false), 2000);
					addToast(
						<Toasts
							title='Toasts notifications'
							icon='VerifiedUser'
							iconColor='primary'
							time='Now'
							isDismiss>
							User registered successfully.
						</Toasts>,
						{
							autoDismiss: true,
							autoDismissTimeout: 2000,
						},
					);
					return thunkApi.fulfillWithValue({
						token: response?.data?.response?.access_token,
					});
				}
			})
			.catch((error) => {
				return thunkApi.rejectWithValue(error);
			});
	},
);

// const islogin =
// 	typeof window !== 'undefined' ? (localStorage.getItem('token') ? true : false) : false;

const initialState = { state: 'check' };

const signupSlice = createSlice({
	name: 'login',
	initialState,
	// reducers: {
	// 	logout: (state, action) => {
	// 		console.log(state, action);
	// 	},
	// },
	extraReducers: (builder) => {
		builder.addCase(userSignUp.pending, (state, action) => {});
		builder.addCase(userSignUp.fulfilled, (state, action) => {});
		builder.addCase(userSignUp.rejected, (state, action) => {});
	},
});

export default signupSlice.reducer;
