import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { userLogin } from '../../../store/slices/login';
import { userSignUp } from '../../../store/slices/signUp';
import { useToasts } from 'react-toast-notifications';
import { encryptionObj, decryption } from '../../Encrypt';
import { deviceLost } from '../../../store/slices/tpaList';
import Textarea from '../../../components/bootstrap/forms/Textarea';

// eslint-disable-next-line react/prop-types

const LoginHeader = ({ isNewUser }) => {
	const linkRes = useSelector((state) => state.tpaListSlice?.chckLink);
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			{/* <div className='text-center h1 fw-bold mt-5'>Welcome</div> */}
			{linkRes?.code === 200 ? (
				<div className='text-center h4 text-muted mb-5'>Reset your password here!</div>
			) : (
				''
			)}
		</>
	);
};

const LostDevice = ({ isSignUp }) => {
	const [isLoding, setIsLoading] = useState(false);
	const [loginPass, setLoginPass] = useState('');
	const [confirmPass, setConfirmPass] = useState('');
	const [message, setMessage] = useState('');
	const [ConfrmPassValid, setCnfrmPassValid] = useState('');
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const [isNewUser, setIsNewUser] = useState(isSignUp);
	const [showConfrmError, setShowCnfrmError] = useState(false);
	const [showMessageError, setShowMessageError] = useState(false);
	const [showLoginPassError, setShowLoginPassError] = useState(false);
	const [passValid, setPassValid] = useState(false);
	const [emailValid, setEmailValid] = useState(false);
	const { addToast } = useToasts();
	const navigate = useNavigate();
	const islogin = useSelector((state) => state.loginSlice.verify);
	const linkRes = useSelector((state) => state.tpaListSlice?.chckLink);

	const handleReset = async (e) => {
		e.preventDefault();
		const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
		if (loginPass.length === 0) {
			setShowLoginPassError(true);
		} else if (confirmPass.length === 0 || !regex.test(confirmPass)) {
			setShowCnfrmError(true);
		} else if (message.length === 0) {
			setShowMessageError(true);
		} else {
			let data = {
				name: loginPass,
				email: confirmPass,
				message: message,
			};

			let finalData = await encryptionObj(data);
			let payload = {
				data: finalData,
			};
			dispatch(deviceLost({ payload, setIsLoading, addToast }));
			return;
		}
	};

	const keyDown = (e) => {
		if (e.charCode === 13) {
			handleReset(e);
		}
	};

	return (
		<PageWrapper
			// title={isNewUser ? 'Sign Up' : 'Login'}
			// title={'Login'}
			className='backgroun-new'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'heading-font text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										{/* <Logo width={200} /> */}
										TRES HEALTH
									</Link>
									<p>YOUR PARTNER TO BETTER HEALTH</p>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-lo10-dark': darkModeStatus,
									})}></div>

								<LoginHeader isNewUser={isNewUser} />
								<form className='row g-4'>
									<div className='col-12 form-main-wrp'>
										<FormGroup id='login-password' isFloating label='Name'>
											<Input
												className={'mb-3'}
												type='text'
												value={loginPass}
												onChange={(e) => {
													setLoginPass(e.target.value);
													setShowLoginPassError(false);
													setPassValid(false);
												}}
											/>
										</FormGroup>
										{showLoginPassError ? (
											<div className='error-wrap'>
												<div className='error lg-msz msz-error-nw'>
													Kindly enter you name.
												</div>
											</div>
										) : null}
										<FormGroup id='login-password' isFloating label='Email'>
											<Input
												className={'mb-3'}
												type='email'
												value={confirmPass}
												onChange={(e) => {
													setConfirmPass(e.target.value);
													setShowCnfrmError(false);
												}}
												onKeyPress={keyDown}
											/>
										</FormGroup>
										{showConfrmError ? (
											<div className='error-wrap'>
												<div className='error lg-msz msz-error-nw'>
													Kindly enter your valid email.
												</div>
											</div>
										) : null}
										<FormGroup
											id='login-password'
											className='form-control custom-textarea'
											isFloating
											label='Reason'>
											<Textarea
												rows='5'
												value={message}
												onChange={(e) => {
													setMessage(e.target.value);
													setShowMessageError(false);
												}}
												onKeyPress={keyDown}
											/>
										</FormGroup>
										{showMessageError ? (
											<div className='error-wrap'>
												<div className='error lg-msz msz-error-nw'>
													Kindly mention the reason.
												</div>
											</div>
										) : null}
									</div>
									<div className='col-12'>
										<Button
											color='warning'
											className='w-100 py-3 login-class'
											onClick={handleReset}>
											Send request
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
						{/* <div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Terms of use
							</a>
						</div> */}
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
LostDevice.propTypes = {
	isSignUp: PropTypes.bool,
};
LostDevice.defaultProps = {
	isSignUp: false,
};

export default LostDevice;
