import axios from 'axios';

let url = process.env.REACT_APP_API_CLIENT_URL;
if (window.location.hostname === 'apanel.tresuat.webbycentral.xyz') {
	url = process.env.REACT_APP_API_URL;
}
if (window.location.hostname === 'apanel.tres.health') {
	url = process.env.REACT_APP_API_LIVE_URL;
}
// const baseURL = process.env.REACT_APP_API_URL + process.env.REACT_APP_PREFIX;
// const baseURLAuth = process.env.REACT_APP_API_URL + process.env.REACT_APP_PREFIX_AUTH;

const axiosInstance = axios.create({
	baseURL: url + process.env.REACT_APP_PREFIX
});

export const axiosInstanceAuth = axios.create({
	baseURL: url + process.env.REACT_APP_PREFIX_AUTH
});

export const axiosInstanceAuth1 = axios.create({
	baseURL: url
});

export const axiosInstanceAuthLogout = axios.create({
	baseURL: url
});

export const axiosInstanceAuth2 = axios.create({
	baseURL: url + process.env.REACT_APP_PREFIX_AUTH2
});

export const axiosInstanceRefresh = axios.create({
	baseURL: url,
  });

axiosInstance.interceptors.request.use(
	function (config) {
		const token = typeof window !== 'undefined' ? localStorage.getItem('token') : null;
		if (token) {
			config.headers.Authorization = ` Bearer ${token}`;
			config.headers.channel = 'ADMIN';
			config.headers.clientId = "admin-web-app";
		}
		else{
			config.headers.Authorization = null;
			config.headers.channel = 'ADMIN';
			config.headers.clientId = "admin-web-app";
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	},
);

axiosInstanceAuth.interceptors.request.use(
	function (config) {
		const token = typeof window !== 'undefined' ? localStorage.getItem('token') : null;
		if (token) {
			config.headers.Authorization = ` Bearer ${token}`;
			config.headers.channel = 'ADMIN';
			config.headers.clientId = "admin-web-app";
		} else {
			config.headers.Authorization = null;
			config.headers.channel = 'ADMIN';
			config.headers.clientId = "admin-web-app";
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	},
);

axiosInstanceAuth1.interceptors.request.use(
	function (config) {
		const token = typeof window !== 'undefined' ? localStorage.getItem('token') : null;
		if (token) {
			config.headers.Authorization = ` Bearer ${token}`;
			config.headers.channel = 'ADMIN';
			config.headers.clientId = "admin-web-app";
		} else {
			config.headers.Authorization = null;
			config.headers.channel = 'ADMIN';
			config.headers.clientId = "admin-web-app";
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	},
);

axiosInstanceAuthLogout.interceptors.request.use(
	function (config) {
		config.headers.channel = 'ADMIN';
		config.headers.clientId = "admin-web-app";
		return config;
	},
	function (error) {
		return Promise.reject(error);
	},
);

axiosInstanceAuth2.interceptors.request.use(
	function (config) {
		const token = typeof window !== 'undefined' ? localStorage.getItem('token') : null;
		if (token) {
			config.headers.Authorization = ` Bearer ${token}`;
			config.headers.channel = 'ADMIN';
			config.headers.clientId = "admin-web-app";
		} else {
			config.headers.Authorization = null;
			config.headers.channel = 'ADMIN';
			config.headers.clientId = "admin-web-app";
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	},
);

axiosInstanceRefresh.interceptors.request.use(
	function (config) {
	  const token =
		typeof window !== "undefined"
		  ? localStorage.getItem("refreshToken")
		  : null;
	  if (token) {
		config.headers.refreshToken = token;
		config.headers.channel = "ADMIN";
		config.headers.ClientId = "admin-web-app";
		config.headers.session = localStorage.getItem("session");
	  } else {
		config.headers.refreshToken = null;
		config.headers.channel = "ADMIN";
		config.headers.ClientId = "admin-web-app";
		config.headers.session = localStorage.getItem("session");
	  }
	  return config;
	},
	function (error) {
	  return Promise.reject(error);
	}
  );

export default axiosInstance;
