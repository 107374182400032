import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance, { axiosInstanceAuth, axiosInstanceAuth1, axiosInstanceAuth2 } from '../../api/axios';
import endPoints from '../../api/endpoints';
import Toasts from '../../components/bootstrap/Toasts';
import { WindowSidebar } from '../../components/icon/bootstrap';
import { decryption } from '../../pages/Encrypt';
import { refreshToken } from './refreshToken';

const initialState = { state: 'check' };

export const tpaList = createAsyncThunk('tpalist', async ({ payload, setIsLoading }, thunkApi) => {
	const response = axiosInstance
		.get(
			endPoints.tpaList +
				'?pageNumber=' +
				payload.pageNumber +
				'&recordsPerPage=' +
				payload.recordsPerPage,
		)
		.then((response) => {
			if (response?.data?.response) {
				setIsLoading(false);
			}
			if (response?.data?.code === 401 || response?.data?.code === 403) {
				localStorage.removeItem('token');
				window.location.href = '/';
			}
			return thunkApi.fulfillWithValue(response?.data);
		})
		.catch((error) => {
			if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
				localStorage.removeItem('token');
				// window.location.href = '/';
				thunkApi.dispatch(refreshToken());
			}
			return thunkApi.rejectWithValue(error);
		});

	return response;
});

export const tpaListById = createAsyncThunk(
	'tpalistById',
	async ({ payload, setIsLoading }, thunkApi) => {
		const response = axiosInstance
			.get(
				endPoints.tpaReportsByDate +
					payload.id +
					'?pageNumber=' +
					payload.pageNumber +
					'&recordsPerPage=' +
					payload.recordsPerPage +
					'&date=' +
					payload.date,
			)
			.then((response) => {
				if (response?.data?.response) {
					setIsLoading(false);
				}
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					localStorage.removeItem('token');
					window.location.href = '/';
				}
				return thunkApi.fulfillWithValue(response.data);
			})
			.catch((error) => {
				if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
					localStorage.removeItem('token');
					// window.location.href = '/';
					thunkApi.dispatch(refreshToken());
				}
				setIsLoading(false);
				return thunkApi.rejectWithValue(error);
			});
		return response;
	},
);

export const tpaListById2 = createAsyncThunk(
	'tpalistById2',
	async ({ payload, setIsLoading }, thunkApi) => {
		const response = axiosInstance
			.get(
				endPoints.tpaReportsByDate +
					payload.id +
					'?pageNumber=' +
					payload.pageNumber +
					'&recordsPerPage=' +
					payload.recordsPerPage +
					'&date=' +
					payload.date,
			)
			.then((response) => {
				if (response?.data?.response) {
					setIsLoading(false);
				}
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					localStorage.removeItem('token');
					window.location.href = '/';
				}
				return thunkApi.fulfillWithValue(response.data);
			})
			.catch((error) => {
				if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
					localStorage.removeItem('token');
					// window.location.href = '/';
					thunkApi.dispatch(refreshToken());
				}
				setIsLoading(false);
				return thunkApi.rejectWithValue(error);
			});
		return response;
	},
);

export const tpaListDateReports = createAsyncThunk(
	'tpalistReportByDate',
	async ({ payload, setIsLoading }, thunkApi) => {
		const response = axiosInstance
			.get(
				endPoints.tpaReports +
					payload?.id +
					'?page_number=' +
					payload?.page_number +
					'&records_per_page=' +
					payload?.records_per_page,
			)
			.then((response) => {
				if (response?.data?.response) {
					setIsLoading(false);
				}
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					localStorage.removeItem('token');
					window.location.href = '/';
				}
				return thunkApi.fulfillWithValue(response.data);
			})
			.catch((error) => {
				if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
					localStorage.removeItem('token');
					// window.location.href = '/';
					thunkApi.dispatch(refreshToken());
				}
				return thunkApi.rejectWithValue(error);
			});
		return response;
	},
);

export const tpaListDateReports2 = createAsyncThunk(
	'tpalistReportByDate2',
	async ({ payload, setIsLoading }, thunkApi) => {
		const response = axiosInstance
			.get(
				endPoints.tpaReports +
					payload?.id +
					'?page_number=' +
					payload?.page_number +
					'&records_per_page=' +
					payload?.records_per_page,
			)
			.then((response) => {
				if (response?.data?.response) {
					setIsLoading(false);
				}
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					localStorage.removeItem('token');
					window.location.href = '/';
				}
				return thunkApi.fulfillWithValue(response.data);
			})
			.catch((error) => {
				if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
					localStorage.removeItem('token');
					// window.location.href = '/';
					thunkApi.dispatch(refreshToken());
				}

				return thunkApi.rejectWithValue(error);
			});

		return response;
	},
);

export const tpaListDailyReports = createAsyncThunk(
	'tpalistReportDaily',
	async (setIsLoading, thunkApi) => {
		const response = axiosInstance
			.get(endPoints.tpaTodayReport)
			.then((response) => {
				if (response?.data?.status === 200) {
					setIsLoading(false);
				}
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					localStorage.removeItem('token');
					window.location.href = '/';
				}
				return thunkApi.fulfillWithValue(response?.data);
			})
			.catch((error) => {
				if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
					localStorage.removeItem('token');
					// window.location.href = '/';
					thunkApi.dispatch(refreshToken());
				}

				return thunkApi.rejectWithValue(error);
			});

		return response;
	},
);

export const addNewTPA = createAsyncThunk(
	'addTPA',
	async ({ payload, addToast, setEditPanel }, thunkApi) => {
		const response = axiosInstance
			.post(endPoints.addTPA, payload)
			.then((response) => {
				if (response?.data?.response === 'TPA is Created') {
					if (response?.data?.code === 401 || response?.data?.code === 403) {
						localStorage.removeItem('token');
						window.location.href = '/';
					}
					setTimeout(() => setEditPanel(false), 2000);
					addToast(
						<Toasts
							title='Toasts notifications'
							icon='VerifiedUser'
							iconColor='success'
							time='Now'
							isDismiss>
							TPA added successfully.
						</Toasts>,
						{
							autoDismiss: true,
							autoDismissTimeout: 2000,
						},
					);
					return thunkApi.fulfillWithValue(response?.data?.response);
				}
				addToast(
					<Toasts
						title='Toasts notifications'
						icon='VerifiedUser'
						iconColor='danger'
						time='Now'
						isDismiss>
						{response?.data?.response}
					</Toasts>,
					{
						autoDismiss: true,
						autoDismissTimeout: 2000,
					},
				);
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					localStorage.removeItem('token');
					window.location.href = '/';
				}
			})
			.catch((error) => {
				if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
					localStorage.removeItem('token');
					// window.location.href = '/';
					thunkApi.dispatch(refreshToken());
				}

				return thunkApi.rejectWithValue(error);
			});

		return response;
	},
);

export const tpaDetails = createAsyncThunk('viewTPA', async (payload, thunkApi) => {
	const response = axiosInstance
		.get(endPoints.viewTPA + payload.tpa_id)
		.then((response) => {
			if (response?.data?.response?.status === 200) {
				
			}
			if (response?.data?.code === 401 || response?.data?.code === 403) {
				localStorage.removeItem('token');
				window.location.href = '/';
			}
			return thunkApi.fulfillWithValue(response.data.response);
		})
		.catch((error) => {
			if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
				localStorage.removeItem('token');
				// window.location.href = '/';
				thunkApi.dispatch(refreshToken());
			}

			return thunkApi.rejectWithValue(error);
		});

	return response;
});

export const editTPA = createAsyncThunk(
	'tpaEdit',
	async (
		{ payload, addToast, setEditPanel2, pageNumber, recordsPerPage, setIsLoading },
		thunkApi,
	) => {
		const response = axiosInstance
			.put(endPoints.editTPA + payload.tpa_id, payload)
			.then((response) => {
				if (response?.data?.response === 'TPA is Updated') {
					if (response?.data?.code === 401 || response?.data?.code === 403) {
						localStorage.removeItem('token');
						window.location.href = '/';
					}
					setTimeout(() => setEditPanel2(false), 2000);
					thunkApi.dispatch(
						tpaList({ payload: { pageNumber, recordsPerPage }, setIsLoading }),
					);
					addToast(
						<Toasts
							title='Toasts notifications'
							icon='VerifiedUser'
							iconColor='success'
							time='Now'
							isDismiss>
							TPA updated successfully.
						</Toasts>,
						{
							autoDismiss: true,
							autoDismissTimeout: 2000,
						},
					);
					return thunkApi.fulfillWithValue(response?.data?.response);
				}
				addToast(
					<Toasts
						title='Toasts notifications'
						icon='VerifiedUser'
						iconColor='danger'
						time='Now'
						isDismiss>
						{response?.data?.response}
					</Toasts>,
					{
						autoDismiss: true,
						autoDismissTimeout: 2000,
					},
				);
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					localStorage.removeItem('token');
					window.location.href = '/';
				}
			})
			.catch((error) => {
				if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
					localStorage.removeItem('token');
					// window.location.href = '/';
					thunkApi.dispatch(refreshToken());
				}

				return thunkApi.rejectWithValue(error);
			});

		return response;
	},
);

export const rawDetails = createAsyncThunk(
	'rawDataa',
	async ({ payload, setIsLoading }, thunkApi) => {
		const response = axiosInstance
			.get(
				endPoints.rawData +
					payload.id +
					'?pageNumber=' +
					payload.pageNumber +
					'&recordsPerPage=' +
					payload.recordsPerPage,
			)
			.then((response) => {
				if (response?.data?.response) {
					setIsLoading(false);
				}
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					localStorage.removeItem('token');
					window.location.href = '/';
				}
				return thunkApi.fulfillWithValue(response.data);
			})
			.catch((error) => {
				if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
					localStorage.removeItem('token');
					// window.location.href = '/';
					thunkApi.dispatch(refreshToken());
				}

				return thunkApi.rejectWithValue(error);
			});

		return response;
	},
);

export const tpaLatestUploads = createAsyncThunk('latestUpload', async (payload, thunkApi) => {
	const response = axiosInstance
		.get(latestFile.viewTPA + payload.tpa_id)
		.then((response) => {
			if (response?.data?.response) {
				
			}
			if (response?.data?.code === 401 || response?.data?.code === 403) {
				localStorage.removeItem('token');
				window.location.href = '/';
			}
			return thunkApi.fulfillWithValue(response.data.response);
		})
		.catch((error) => {
			if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
				localStorage.removeItem('token');
				// window.location.href = '/';
				thunkApi.dispatch(refreshToken());
			}

			return thunkApi.rejectWithValue(error);
		});

	return response;
});

export const providerCount = createAsyncThunk('provCount', async (_, thunkApi) => {
	const response = axiosInstance
		.get(endPoints.provider_count)
		.then((response) => {
			if (response?.data?.response) {
				// setIsLoading(false);
			}
			if (response?.data?.code === 401 || response?.data?.code === 403) {
				localStorage.removeItem('token');
				window.location.href = '/';
			}
			return thunkApi.fulfillWithValue(response?.data);
		})
		.catch((error) => {
			if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
				localStorage.removeItem('token');
				// window.location.href = '/';
				thunkApi.dispatch(refreshToken());
			}
			return thunkApi.rejectWithValue(error);
		});

	return response;
});

export const activeProviderCount = createAsyncThunk('activeProvCount', async (_, thunkApi) => {
	const response = axiosInstance
		.get(endPoints.activeProvCount)
		.then((response) => {
			if (response?.data?.response) {
				// setIsLoading(false);
			}
			if (response?.data?.code === 401 || response?.data?.code === 403) {
				localStorage.removeItem('token');
				window.location.href = '/';
			}
			return thunkApi.fulfillWithValue(response?.data);
		})
		.catch((error) => {
			if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
				localStorage.removeItem('token');
				// window.location.href = '/';
				thunkApi.dispatch(refreshToken());
			}
			return thunkApi.rejectWithValue(error);
		});

	return response;
});

export const activeProviderList = createAsyncThunk(
	'activeProvList',
	async ({ payload, setIsLoading }, thunkApi) => {
		const response = axiosInstanceAuth
			.get(
				endPoints.activeProvList +
					'?pageNumber=' +
					payload?.page_number +
					'&recordsPerPage=' +
					payload?.records_per_page,
			)
			.then((response) => {
				if (response?.data?.response) {
					setIsLoading(false);
				}
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					localStorage.removeItem('token');
					window.location.href = '/';
				}
				return thunkApi.fulfillWithValue(response?.data);
			})
			.catch((error) => {
				if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
					localStorage.removeItem('token');
					// window.location.href = '/';
					thunkApi.dispatch(refreshToken());
				}
				return thunkApi.rejectWithValue(error);
			});

		return response;
	},
);

export const ProviderList = createAsyncThunk(
	'ProvList',
	async ({ payload, setIsLoading, key }, thunkApi) => {
		let query =
			key === 'small'
				? '?pageNumber=' +
				  payload?.page_number +
				  '&recordsPerPage=' +
				  payload?.records_per_page
				: '?pageNumber=' +
				  payload?.page_number +
				  '&recordsPerPage=' +
				  payload?.records_per_page +
				  '&startDate=' +
				  payload?.startDate +
				  'T00:00:00.000-00:00' +
				  '&endDate=' +
				  payload?.endDate +
				  'T00:00:00.000-00:00';
		const response = axiosInstanceAuth
			.get(
				endPoints.providerList + query,

				//  +
				// payload?.startDate !==
				// ''
				// ? '&startDate=' + payload?.starrDate
				// : null + payload?.endDate !== ''
				// ? '&endDate=' + payload?.endDate
				// : null
			)
			.then((response) => {
				if (response?.data?.response) {
					setIsLoading(false);
				}
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					localStorage.removeItem('token');
					window.location.href = '/';
				}
				return thunkApi.fulfillWithValue(response?.data);
			})
			.catch((error) => {
				if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
					localStorage.removeItem('token');
					// window.location.href = '/';
					thunkApi.dispatch(refreshToken());
				}
				return thunkApi.rejectWithValue(error);
			});

		return response;
	},
);

export const providerDetail = createAsyncThunk(
	'provDetail',
	async ({ payload, setIsLoading }, thunkApi) => {
		const response = axiosInstanceAuth
			.get(endPoints.detailProv + '/' + payload?.id)
			.then((response) => {
				if (response?.data?.response) {
					setIsLoading(false);
				}
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					localStorage.removeItem('token');
					window.location.href = '/';
				}
				return thunkApi.fulfillWithValue(response?.data?.response);
			})
			.catch((error) => {
				if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
					localStorage.removeItem('token');
					// window.location.href = '/';
					thunkApi.dispatch(refreshToken());
				}
				return thunkApi.rejectWithValue(error);
			});

		return response;
	},
);

export const serachDetail = createAsyncThunk(
	'searchCount',
	async ({ payload, setIsLoading }, thunkApi) => {
		const response = axiosInstance
			.get(endPoints.searchesCount + '/' + payload?.id)
			.then((response) => {
				if (response?.data?.response) {
					setIsLoading(false);
				}
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					localStorage.removeItem('token');
					window.location.href = '/';
				}
				return thunkApi.fulfillWithValue(response?.data?.response);
			})
			.catch((error) => {
				if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
					localStorage.removeItem('token');
					// window.location.href = '/';
					thunkApi.dispatch(refreshToken());
				}
				return thunkApi.rejectWithValue(error);
			});

		return response;
	},
);

export const adminSerach = createAsyncThunk('searchAdmin', async (_, thunkApi) => {
	const response = axiosInstance
		.get(endPoints.adminSearch)
		.then((response) => {
			if (response?.data?.response) {
				// setIsLoading(false);
			}
			if (response?.data?.code === 401 || response?.data?.code === 403) {
				localStorage.removeItem('token');
				window.location.href = '/';
			}
			return thunkApi.fulfillWithValue(response?.data?.response);
		})
		.catch((error) => {
			if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
				localStorage.removeItem('token');
				// window.location.href = '/';
				thunkApi.dispatch(refreshToken());
			}
			return thunkApi.rejectWithValue(error);
		});

	return response;
});

export const passwordChange = createAsyncThunk(
	'changePass',
	async (
		{ payload, addToast, setIsLoading, setCurrentPass, setNewPass, setConfrmPass },
		thunkApi,
	) => {
		const response = axiosInstanceAuth1
			.post(endPoints.changePassword, payload)
			.then((response) => {
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					setIsLoading(false);
					localStorage.removeItem('token');
					window.location.href = '/';
				}
				if (response?.data?.code !== 200) {
					addToast(
						<Toasts
							title='Toasts notifications'
							icon='VerifiedUser'
							iconColor='danger'
							time='Now'
							isDismiss>
							{response?.data?.response}
						</Toasts>,
						{
							autoDismiss: true,
							autoDismissTimeout: 2000,
						},
					);
					setIsLoading(false);
					setCurrentPass('');
					setNewPass('');
					setConfrmPass('');
				} else {
					addToast(
						<Toasts
							title='Toasts notifications'
							icon='VerifiedUser'
							iconColor='success'
							time='Now'
							isDismiss>
							{response?.data?.response}
						</Toasts>,
						{
							autoDismiss: true,
							autoDismissTimeout: 2000,
						},
					);
					setIsLoading(false);
					setCurrentPass('');
					setNewPass('');
					setConfrmPass('');
					return thunkApi.fulfillWithValue(response?.data?.response);
				}
			})
			.catch((error) => {
				if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
					setIsLoading(false);
					localStorage.removeItem('token');
					// window.location.href = '/';
					thunkApi.dispatch(refreshToken());
				}

				return thunkApi.rejectWithValue(error);
			});

		return response;
	},
);

export const viewProfile = createAsyncThunk('viewProfile', async (_, thunkApi) => {
	const response = axiosInstanceAuth
		.get(endPoints.profile)
		.then(async (response) => {
			if (response?.data?.code === 401 || response?.data?.code === 403) {
				// setIsLoading(false);
				localStorage.removeItem('token');
				window.location.href = '/';
				let payload = {
					refresh_token: localStorage.getItem('refresh_token'),
				};
				await thunkApi.dispatch(refreshToken(payload));
				setTimeout(() => {
					notify.errorToast('Please sign in');
				}, 1500);
			}
			let decrypt = await decryption(response?.data?.response);
			let parsedData = JSON.parse(decrypt);
			if (response?.data?.code === 200) {
				//   setIsLoading(false);
				return thunkApi.fulfillWithValue(parsedData);
			}
		})
		.catch((error) => {
			if (error?.response?.data?.code === 401 || error?.response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
				//   setIsLoading(false);
				// localStorage.removeItem("customer_token");
				// window.location.href = "/";
				// notify.errorToast("Please sign in");
				thunkApi.dispatch(refreshToken());
			}
			// setIsLoading(false);
			// notify.errorToast("Oops! something went wrong");
			console.log('error', response);
			return thunkApi.rejectWithValue(error);
		});
	return response;
});

export const resetLink = createAsyncThunk(
	'resetLink',
	async ({ payload, setIsLoading, addToast }, thunkApi) => {
		const response = axiosInstanceAuth2
			.post(endPoints.resetPass + '?encryptedEmailId=' + payload)
			.then(async (response) => {
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					localStorage.removeItem('token');
					setIsLoading(false);
				}
				if (response?.data?.code !== 200) {
					setIsLoading(false);
					addToast(
						<Toasts
							title='Toasts notifications'
							icon='VerifiedUser'
							iconColor='danger'
							time='Now'
							isDismiss>
							{response?.data?.response}
						</Toasts>,
						{
							autoDismiss: true,
							autoDismissTimeout: 2000,
						},
					);
				} else {
					setIsLoading(false);
					addToast(
						<Toasts
							title='Toasts notifications'
							icon='VerifiedUser'
							iconColor='success'
							time='Now'
							isDismiss>
							{response?.data?.response}
						</Toasts>,
						{
							autoDismiss: true,
							autoDismissTimeout: 2000,
						},
					);
					return thunkApi.fulfillWithValue(response?.data);
				}
			})
			.catch((error) => {
				if (
					response?.data?.code === 401 ||
					response?.data?.code === 403 ||
					response?.data?.code === 400 || error?.response?.status === 401 || error?.response?.status === 403
				) {
					localStorage.removeItem('token');
					thunkApi.dispatch(refreshToken());
					setIsLoading(false);
					notify.errorToast(error?.data?.resonse);
				}
				setIsLoading(false);
				return thunkApi.rejectWithValue(error);
			});
		return response;
	},
);

export const checkLink = createAsyncThunk(
	'checkLink',
	async ({ payload, setIsLoading, addToast }, thunkApi) => {
		let encode = encodeURIComponent(payload?.id);
		const response = axiosInstanceAuth2
			.get(endPoints.linkCheck + '?encryptedEmailId=' + encode + '&key=' + payload?.key)
			.then(async (response) => {
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					setIsLoading(false);
					localStorage.removeItem('token');
				}
				if (response?.data?.code !== 200) {
					setIsLoading(false);
					addToast(
						<Toasts
							title='Toasts notifications'
							icon='VerifiedUser'
							iconColor='danger'
							time='Now'
							isDismiss>
							{response?.data?.response}
						</Toasts>,
						{
							autoDismiss: true,
							autoDismissTimeout: 2000,
						},
					);
					return thunkApi.fulfillWithValue(response?.data);
					// setTimeout(() => (window.location = '/'), 3000);
				} else {
					setIsLoading(false);
					return thunkApi.fulfillWithValue(response?.data);
				}
			})
			.catch((error) => {
				if (
					response?.data?.code === 401 ||
					response?.data?.code === 403 ||
					response?.data?.code === 400 || error?.response?.status === 401 || error?.response?.status === 403
				) {
					setIsLoading(false);
					localStorage.removeItem('token');
					thunkApi.dispatch(refreshToken());
				}
				setIsLoading(false);
				return thunkApi.rejectWithValue(error);
			});
		return response;
	},
);

export const passReset = createAsyncThunk(
	'passReset',
	async ({ payload, setIsLoading, addToast }, thunkApi) => {
		const response = axiosInstanceAuth1
			.post(endPoints.pass_reset, payload)
			.then(async (response) => {
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					setIsLoading(false);
				}
				if (response?.data?.code !== 200) {
					setIsLoading(false);
					addToast(
						<Toasts
							title='Toasts notifications'
							icon='VerifiedUser'
							iconColor='danger'
							time='Now'
							isDismiss>
							{response?.data?.response}
						</Toasts>,
						{
							autoDismiss: true,
							autoDismissTimeout: 2000,
						},
					);
					return thunkApi.fulfillWithValue(response?.data);
				} else if (response?.data?.code === 200) {
					setIsLoading(false);
					// setTimeout(() => (window.location = "/"), 3000);
					addToast(
						<Toasts
							title='Toasts notifications'
							icon='VerifiedUser'
							iconColor='success'
							time='Now'
							isDismiss>
							{response?.data?.response}
						</Toasts>,
						{
							autoDismiss: true,
							autoDismissTimeout: 2000,
						},
					);
					setTimeout(() => (window.location = '/'), 3000);
					return thunkApi.fulfillWithValue(response?.data);
				} else {
					setIsLoading(false);
					return thunkApi.fulfillWithValue(response?.data);
				}
			})
			.catch((error) => {
				if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
					setIsLoading(false);
					localStorage.removeItem('token');
					thunkApi.dispatch(refreshToken());
				}
				setIsLoading(false);
				return thunkApi.rejectWithValue(error);
			});
		return response;
	},
);

export const deviceLost = createAsyncThunk(
	'device',
	async ({ payload, setIsLoading, addToast }, thunkApi) => {
		const response = axiosInstanceAuth
			.post(endPoints.lost_device, payload)
			.then(async (response) => {
				if (response?.data?.code === 401 || response?.data?.code === 403) {
					setIsLoading(false);
				}
				if (response?.data?.code !== 200) {
					setIsLoading(false);
					addToast(
						<Toasts
							title='Toasts notifications'
							icon='VerifiedUser'
							iconColor='danger'
							time='Now'
							isDismiss>
							{response?.data?.response}
						</Toasts>,
						{
							autoDismiss: true,
							autoDismissTimeout: 2000,
						},
					);
					return thunkApi.fulfillWithValue(response?.data);
				} else if (response?.data?.code === 200) {
					setIsLoading(false);
					// setTimeout(() => (window.location = "/"), 3000);
					addToast(
						<Toasts
							title='Toasts notifications'
							icon='VerifiedUser'
							iconColor='success'
							time='Now'
							isDismiss>
							{response?.data?.response}
						</Toasts>,
						{
							autoDismiss: true,
							autoDismissTimeout: 2000,
						},
					);
					setTimeout(() => (window.location = '/'), 3000);
					// return thunkApi.fulfillWithValue(response?.data);
				} else {
					setIsLoading(false);
					return thunkApi.fulfillWithValue(response?.data);
				}
			})
			.catch((error) => {
				if (response?.data?.code === 401 || response?.data?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403) {
					setIsLoading(false);
					localStorage.removeItem('token');
					thunkApi.dispatch(refreshToken());
				}
				setIsLoading(false);
				return thunkApi.rejectWithValue(error);
			});
		return response;
	},
);

export const tpaListSlice = createSlice({
	name: 'tpaList',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(tpaList.pending, (state, action) => {});
		builder.addCase(tpaList.fulfilled, (state, action) => {
			state.tpa = action.payload;
		});
		builder.addCase(tpaList.rejected, (state, action) => {});
		builder.addCase(tpaListById.pending, (state, action) => {
			state.tpaById = undefined;
		});
		builder.addCase(tpaListById.fulfilled, (state, action) => {
			state.tpaById2 = action.payload;
		});
		builder.addCase(tpaListById.rejected, (state, action) => {});
		builder.addCase(tpaListById2.pending, (state, action) => {
			// state.tpaById = undefined;
		});
		builder.addCase(tpaListById2.fulfilled, (state, action) => {
			state.tpaById = action.payload;
		});
		builder.addCase(tpaListById2.rejected, (state, action) => {});
		builder.addCase(tpaListDateReports.pending, (state, action) => {
			state.tpaDate = undefined;
		});
		builder.addCase(tpaListDateReports.fulfilled, (state, action) => {
			state.tpaDate = action.payload;
		});
		builder.addCase(tpaListDateReports.rejected, (state, action) => {});
		builder.addCase(tpaListDateReports2.pending, (state, action) => {
			// state.tpaDate = undefined;
		});
		builder.addCase(tpaListDateReports2.fulfilled, (state, action) => {
			state.tpaDate = action.payload;
		});
		builder.addCase(tpaListDateReports2.rejected, (state, action) => {});
		builder.addCase(tpaListDailyReports.pending, (state, action) => {
			state.tpaDate = undefined;
		});
		builder.addCase(tpaListDailyReports.fulfilled, (state, action) => {
			state.tpaDaily = action.payload;
		});
		builder.addCase(tpaListDailyReports.rejected, (state, action) => {});
		builder.addCase(addNewTPA.pending, (state, action) => {
			state.tpaDate = undefined;
			delete state.tpaAdd;
		});
		builder.addCase(addNewTPA.fulfilled, (state, action) => {
			state.tpaAdd = action.payload;
		});
		builder.addCase(addNewTPA.rejected, (state, action) => {});
		builder.addCase(tpaDetails.pending, (state, action) => {});
		builder.addCase(tpaDetails.fulfilled, (state, action) => {
			state.viewTPA = action.payload;
		});
		builder.addCase(tpaDetails.rejected, (state, action) => {});
		builder.addCase(editTPA.pending, (state, action) => {
			delete state.tpaEdit;
		});
		builder.addCase(editTPA.fulfilled, (state, action) => {
			state.tpaEdit = action.payload;
		});
		builder.addCase(editTPA.rejected, (state, action) => {});
		builder.addCase(rawDetails.pending, (state, action) => {
			delete state.tpaEdit;
		});
		builder.addCase(rawDetails.fulfilled, (state, action) => {
			state.rawDataa = action.payload;
		});
		builder.addCase(rawDetails.rejected, (state, action) => {});
		builder.addCase(tpaLatestUploads.pending, (state, action) => {
			delete state.tpaEdit;
		});
		builder.addCase(tpaLatestUploads.fulfilled, (state, action) => {
			state.latestUploads = action.payload;
		});
		builder.addCase(tpaLatestUploads.rejected, (state, action) => {});
		builder.addCase(providerCount.pending, (state, action) => {});
		builder.addCase(providerCount.fulfilled, (state, action) => {
			state.activeProv = action.payload;
		});
		builder.addCase(providerCount.rejected, (state, action) => {});
		builder.addCase(activeProviderCount.pending, (state, action) => {});
		builder.addCase(activeProviderCount.fulfilled, (state, action) => {
			state.activeCount = action.payload;
		});
		builder.addCase(activeProviderCount.rejected, (state, action) => {});
		builder.addCase(activeProviderList.pending, (state, action) => {});
		builder.addCase(activeProviderList.fulfilled, (state, action) => {
			state.activeList = action.payload;
		});
		builder.addCase(activeProviderList.rejected, (state, action) => {});
		builder.addCase(ProviderList.pending, (state, action) => {});
		builder.addCase(ProviderList.fulfilled, (state, action) => {
			state.provList = action.payload;
		});
		builder.addCase(ProviderList.rejected, (state, action) => {});
		builder.addCase(providerDetail.pending, (state, action) => {});
		builder.addCase(providerDetail.fulfilled, (state, action) => {
			state.detailsProvider = action.payload;
		});
		builder.addCase(providerDetail.rejected, (state, action) => {});
		builder.addCase(serachDetail.pending, (state, action) => {});
		builder.addCase(serachDetail.fulfilled, (state, action) => {
			state.detailsSearch = action.payload;
		});
		builder.addCase(serachDetail.rejected, (state, action) => {});
		builder.addCase(adminSerach.pending, (state, action) => {});
		builder.addCase(adminSerach.fulfilled, (state, action) => {
			state.searchAd = action.payload;
		});
		builder.addCase(adminSerach.rejected, (state, action) => {});

		builder.addCase(viewProfile.pending, (state, action) => {
			
		});
		builder.addCase(viewProfile.fulfilled, (state, action) => {
			state.profileInfo = action.payload;
			
		});
		builder.addCase(viewProfile.rejected, (state, action) => {
			
		});
		builder.addCase(checkLink.pending, (state, action) => {
			
		});
		builder.addCase(checkLink.fulfilled, (state, action) => {
			state.chckLink = action.payload;
			
		});
		builder.addCase(checkLink.rejected, (state, action) => {
			
		});
	},
});

export default tpaListSlice.reducer;
