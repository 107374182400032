import React from 'react';
import CryptoJS from 'crypto-js';
const helloKey = '8383922a202d44e6d58f8d74179672ba';
const i_vector = process.env.REACT_APP_ENCRYPT_ID;

// --------encrypt single string--------//
export const encryption = async (msg) => {
	try {
		const secretKey = process.env.REACT_APP_ENCRYPT_KEY;
		let key = CryptoJS.enc.Utf8.parse(secretKey);
		let iv = CryptoJS.enc.Utf8.parse(i_vector);
		let encryptedCP = CryptoJS.AES.encrypt(msg, key, { iv: iv });
		let cryptText = encryptedCP.toString();

		return cryptText;
	} catch (error) {}
};
// --------decrypt single string--------
export const decryption = async (msg) => {
	try {
		const secretKey = process.env.REACT_APP_ENCRYPT_KEY;
		let key = CryptoJS.enc.Latin1.parse(secretKey);
		let iv = CryptoJS.enc.Latin1.parse(i_vector);

		let ctx = CryptoJS.enc.Base64.parse(msg);

		let enc = CryptoJS.lib.CipherParams.create({ ciphertext: ctx });
		let decryptText = CryptoJS.AES.decrypt(enc, key, { iv: iv }).toString(CryptoJS.enc.Utf8);

		return await decryptText;
	} catch (err) {}
};
// --------encrypt object--------
export const encryptionObj = async (msg) => {
	try {
		const secretKey = process.env.REACT_APP_ENCRYPT_KEY;
		let key = CryptoJS.enc.Utf8.parse(secretKey);
		let iv = CryptoJS.enc.Utf8.parse(i_vector);
		let encryptedCP = CryptoJS.AES.encrypt(JSON.stringify(msg), key, {
			iv: iv,
		});
		let cryptText = encryptedCP.toString();
		return cryptText;
	} catch (err) {}
};

// --------decrypt object--------
export const decryptionObj = async (msg) => {
	try {
		const secretKey = process.env.REACT_APP_ENCRYPT_KEY;
		let key = CryptoJS.enc.Latin1.parse(secretKey);
		let iv = CryptoJS.enc.Latin1.parse(i_vector);
		let ctx = CryptoJS.enc.Base64.parse(msg);
		let enc = CryptoJS.lib.CipherParams.create({ ciphertext: ctx });
		return CryptoJS.AES.decrypt(enc, key, { iv: iv }).toString(CryptoJS.enc.Utf8);
	} catch (err) {}
};
